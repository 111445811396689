import React, {useState, ReactNode, useRef, useEffect} from 'react';
import './Accordion.scss';


type AccordionProps = {
    title: string;
    children: ReactNode;
    headerContent?: ReactNode;
    inverted?: boolean
    saveName?: string
    accordion?: boolean
};

const Accordion = ({ title, children, headerContent, inverted = false, saveName, accordion = true }: AccordionProps) => {
    const initialState = localStorage.getItem(saveName ?? '') === 'true';
    const [isOpen, setIsOpen] = useState(accordion ? initialState : true);
    const [block, setBlock] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleAccordion = () => {
        if (!accordion || block) return;
        const _isOpen = !isOpen;
        setIsOpen(_isOpen);
        if (saveName) {
            localStorage.setItem(saveName, _isOpen.toString());
        }
    }

    useEffect(() => {
        if (!accordion && contentRef.current)
            contentRef.current.style.overflow = 'visible';
    }, [accordion, contentRef])

    useEffect(() => {
        if (saveName) {
            const savedState = localStorage.getItem(saveName) === 'true';
            setIsOpen(savedState);
        }
    }, [saveName]);

    const refresh = () => {
        if (contentRef.current && accordion) {

            setBlock(true);
            if (isOpen) {
                contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
            } else {
                contentRef.current.style.maxHeight = '0';
                contentRef.current.style.overflow = 'hidden';
            }

            setTimeout(() => {
                if (contentRef.current && isOpen) {
                    contentRef.current.style.overflow = 'visible';
                }
                setBlock(false);
            }, 1000);

        }
    }

    const updateMaxHeight = () => {

        if (contentRef.current && accordion && isOpen) {
        //    const transition = contentRef.current.style.transition;
        //    contentRef.current.style.transition = 'none';
            contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;

            // setTimeout(() => {
            //     if (contentRef.current && isOpen) {
            //         contentRef.current.style.transition = transition;
            //         contentRef.current.style.overflow = 'visible';
            //     }
            // }, 1000);
        }

    };

    // Обсервер для отслеживания изменений в содержимом
    useEffect(() => {
        if (!contentRef.current || !isOpen) return;
        const observer = new MutationObserver(() => updateMaxHeight());
        observer.observe(contentRef.current, {
            childList: true,
            subtree: true,
            characterData: true,
        });

        return () => observer.disconnect();
        // eslint-disable-next-line
    }, [isOpen]);


    useEffect(() => {
        refresh()
        // eslint-disable-next-line
    }, [ isOpen]);


    useEffect(() => {
        window.addEventListener('resize', updateMaxHeight);
        return () => window.removeEventListener('resize', updateMaxHeight);
        // eslint-disable-next-line
    }, []);

    const accordionClasses = `accordion ${inverted ? 'inverted' : ''}`;
    const triangleClasses = `triangle ${isOpen ? 'open' : ''} ${inverted ? 'inverted' : ''}`;

    return (
        <div className={accordionClasses}>
            <div className="accordion-title" >
                <div onClick={toggleAccordion} className='triangle_header'>
                    {accordion && (<div className='triangle-container'> <div className={triangleClasses} ></div></div>)}
                    <div className="title-content"><h3>{title}</h3></div>
                </div>
                {headerContent}

            </div>
            <div ref={contentRef} className={`accordion-content ${isOpen ? 'expanded' : 'collapsed'}`}>
                {children}
            </div>
        </div>
    );
};

export default Accordion;
