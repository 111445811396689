import {toast} from "react-toastify";



export const showError = (error: string, inverted: boolean = false, autoClose : number | false | undefined = 3000) => {
    toast.error(error, {
        theme: inverted ? 'dark' : 'light',
        autoClose,
        position: "top-center",

    });
    console.error(error);
}

export const showSuccess = (text: string, inverted: boolean = false, autoClose : number | false | undefined = 3000) => {
    toast.success(text, {
        theme: inverted ? 'dark' : 'light',
        autoClose,
        position: "top-center",

    });
}


export const formatDateUTC = (dateString: string|Date): string => {

    const date =  new Date(dateString);
    // Создаём новую дату, добавляя 3 часа (3 * 60 * 60 * 1000 миллисекунд)
    const utcPlusThree = new Date(date.getTime() + 3 * 60 * 60 * 1000);

    const year = utcPlusThree.getUTCFullYear();
    const month = String(utcPlusThree.getUTCMonth() + 1).padStart(2, '0');
    const day = String(utcPlusThree.getUTCDate()).padStart(2, '0');
    const hours = String(utcPlusThree.getUTCHours()).padStart(2, '0');
    const minutes = String(utcPlusThree.getUTCMinutes()).padStart(2, '0');
    const seconds = String(utcPlusThree.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


export const formatDate = (date: Date | null): string => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


export const monthDiff = (date1: Date, date2: Date): number => {
    const millisecondsPerMonth = 1000 * 60 * 60 * 24 * 30.44;
    const diffInMilliseconds = date2.getTime() - date1.getTime();
    return diffInMilliseconds / millisecondsPerMonth;
};
