import './Products.scss'
import Accordion from "../Accordion/Accordion";
import {
    Button,
    Checkbox,
    Segment,
    Icon,
    Grid,
    GridRow,
    GridColumn,
    Divider,
    Input,
    Confirm,
} from "semantic-ui-react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useGlobalContext} from "../GlobalProvider";
import {Select, SelectItem} from "../Select/Select"
import {Sidebar} from "../Sidebar/Sidebar";
import {
    ApiProductLink,
    ApiResponse, ApiTelegramAccounts,
    Field, ProductLink, SettingConnect,
    SettingProduct,
    StorageFBSArray,
    TableControls, TableProductLimitsSort
} from "../../types";
import {
    getField, getOrDeleteTelegramAccounts, getProductAttributes, getProductLinks, getStatusProcess,
    getWarehouses,
    putSettingProducts,
    runProcess
} from "../../requests";
import {formatDate, formatDateUTC, showError, showSuccess} from "../../tools";
import TableProductLinks from "./TableProductLinks";
import {ButtonFilter} from "./ButtonFilter";


const initTableControls: TableControls = {
    page: 1,
    perPage: 50,
    totalPages: 50,
}

const initTableProductLimitsSort: TableProductLimitsSort = {

    tab: 'products',
    status: 'ALL',
    totalPages: 1,
}

type ProductsProps = {

    settingProduct: SettingProduct;
    settingConnect: SettingConnect;
    setSettingProduct: (value: React.SetStateAction<SettingProduct>) => void;

}

export const Products = ({settingProduct, settingConnect, setSettingProduct}: ProductsProps) => {
    const {inverted, activeSettingsId, account_id, connected} = useGlobalContext();
    const [advancedSettingOpen, setAdvancedSettingOpen] = useState(false);
    const [telegramSettingOpen, setTelegramSettingOpen] = useState(false);
    const [telegramUserNames, setTelegramUserNames] = useState<string[]>([]);

    const [TableControls, setTableControls] = useState<TableControls>({
        ...initTableControls,
        perPage: Number(localStorage.getItem('TableProductLimitsPerPage') ?? 50)
    });
    const [tableProductLimitsSort, setTableProductLimitsSort] = useState<TableProductLimitsSort>(initTableProductLimitsSort);
    const [tableProductLimitsData, setTableProductLimitsData] = useState<ProductLink[]>([]);

    const [searchText, setSearchText] = useState<string>('');

    const [listSelect, setListSelect] = useState<SelectItem[]>([]);
    const [openConfirmProductMoyskladToWildberries, setOpenConfirmProductMoyskladToWildberries] = useState(false);
    const [openConfirmProductTransferBalances, setOpenConfirmProductTransferBalances] = useState(false);
    const [openConfirmUploadPriceMoyskladToWildberries, setOpenConfirmUploadPriceMoyskladToWildberries] = useState(false);
    const [openConfirmProductPriceMoyskladToWildberries, setOpenConfirmProductPriceMoyskladToWildberries] = useState(false);
    const [openConfirmSyncProduct, setOpenConfirmSyncProduct] = useState(false);
    const [openConfirmUnloadFbsRemains, setOpenConfirmUnloadFbsRemains] = useState(false);
    const [timers, setTimers] = useState<Map<string, NodeJS.Timeout>>(new Map());
    const timersRef = useRef<Map<string, NodeJS.Timeout>>(timers);





    const handleOpenTelegramAccounts = (deleteUserName: string = '') => {
        getOrDeleteTelegramAccounts(account_id, activeSettingsId, deleteUserName)
            .then(value => {
                const data: ApiTelegramAccounts = value.data;
                if (data.success) {

                    if (data && Array.isArray(data.data)) {
                        setTelegramSettingOpen(true);
                        setTelegramUserNames(data.data);
                    }
                } else {
                    if (data.error)
                        showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleOpenSelectMoySklad = (url: string) => {
        setListSelect([]);

        getField(account_id, url)
            .then(value => {

                const data: ApiResponse<SelectItem[]> = value.data;
                if (data.success && data.data) {
                    setListSelect(data.data);
                } else {
                    if (data.error)
                        showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    const handleOpenSelectWildberries = (url: string) => {
        setListSelect([]);
        getWarehouses(account_id, url, activeSettingsId)
            .then(value => {

                const data: ApiResponse<SelectItem[]> = value.data;
                if (data.success && data.data) {
                    setListSelect(data.data);
                } else {
                    if (data.error)
                        showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleProductLinks = () => {
        getProductLinks(account_id, activeSettingsId, TableControls.page, TableControls.perPage, tableProductLimitsSort.tab, tableProductLimitsSort.status, searchText)
            .then(value => {

                const responce: ApiProductLink = value.data;
                if (responce.success && responce.data) {
                    const data = responce.data;
                    setTableProductLimitsData(data.data);
                    setTableProductLimitsSort(data.tableProductLimitsSort);
                    setTableControls(prevState => ({
                        ...prevState,
                        totalPages: data.tableProductLimitsSort.totalPages
                    }))
                    setSettingProduct(prevState => (
                        {
                            ...prevState,
                            'last_sync': data.last_sync_time.last_sync,
                            'last_balances_send': data.last_sync_time.last_balances_send,
                            'last_prices_send': data.last_sync_time.last_prices_send
                        }));
                } else {
                    if (responce.error)
                        showError(responce.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    useEffect(() => {
        if (!account_id || !activeSettingsId) return;
        handleProductLinks();
    }, [account_id, activeSettingsId, TableControls.page, TableControls.perPage, tableProductLimitsSort.tab, tableProductLimitsSort.status])

    // useEffect(() => {
    //     setTableProductLimitsSort(prevState => ({...prevState, status: 'ALL'}));
    // }, [tableProductLimitsSort.tab])

    const handleOpenSelectProductAttributes = (sort: string[]) => {
        setListSelect([]);

        getProductAttributes(account_id, sort)
            .then(value => {

                const data: ApiResponse<SelectItem[]> = value.data;
                if (data.success && data.data) {
                    setListSelect(data.data);
                } else {
                    if (data.error)
                        showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }



    const updateSettingProduct = (key: string, value: any) => {
        setSettingProduct(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const updateSettingProductSelect = (key: string, value: any) => {
        setSettingProduct(prev => ({
            ...prev,
            [key + "_id"]: value.id,
            [key + "_name"]: value.name,
        }))
    }


    const getSelectDefaultValue = (key: string): Field => {
        if (!settingProduct) return {id: '', name: ''};
        return {
            id: settingProduct[key + '_id'],
            name: settingProduct[key + '_name']
        };
    };

    const getStorageFbsDefaultValue = (key: string, index: number): Field => {
        if (Array.isArray(settingProduct.storage_fbs) && settingProduct.storage_fbs.length > 0)
            return {
                id: settingProduct.storage_fbs[index][key + '_id'],
                name: settingProduct.storage_fbs[index][key + '_name']
            };
        return {
            id: '',
            name: ''
        };
    };

    const getStorageFbsAccountingMinimumBalanceValue = (index: number): number => {
        if (Array.isArray(settingProduct.storage_fbs) && settingProduct.storage_fbs.length > 0) {
            if ('accounting_minimum_balance_count' in settingProduct.storage_fbs[index]) {
                return settingProduct.storage_fbs[index].accounting_minimum_balance_count;
            }
        }
        return 0;
    }

    const storageFbsRemoveItem = (index: number) => {
        setSettingProduct(prevState => {
            if (!prevState.storage_fbs) {
                return prevState;
            }

            return {
                ...prevState,
                storage_fbs: prevState.storage_fbs.filter((_, i) => i !== index)
            };
        });

    };

    const storageFbsAddItem = () => {
        const newItem: StorageFBSArray = {
            wildberries_id: '',
            wildberries_name: '',
            moysklad_id: '',
            moysklad_name: '',
            accounting_minimum_balance_count: 0
        };

        setSettingProduct(prevState => {
            const updatedStorageFBS = prevState.storage_fbs ? [...prevState.storage_fbs, newItem] : [newItem];

            return {
                ...prevState,
                storage_fbs: updatedStorageFBS
            };
        });
    };


    const storageFbsSelectItem = (type: 'wildberries' | 'moysklad', selectedItem: SelectItem, index: number) => {

        setSettingProduct(prevState => {
            if (!prevState.storage_fbs) return prevState;


            const updatedStorageFBS = [...prevState.storage_fbs];

            if (type === 'wildberries') {
                updatedStorageFBS[index] = {
                    ...updatedStorageFBS[index],
                    wildberries_id: selectedItem.id,
                    wildberries_name: selectedItem.name
                };
            } else if (type === 'moysklad') {
                updatedStorageFBS[index] = {
                    ...updatedStorageFBS[index],
                    moysklad_id: selectedItem.id,
                    moysklad_name: selectedItem.name
                };
            }

            return {
                ...prevState,
                storage_fbs: updatedStorageFBS
            };
        });
    };

    const storageFbsChangeAccountingMinimumBalanceCount = (count: number, index: number) => {

        setSettingProduct(prevState => {
            if (!prevState.storage_fbs) return prevState;

            const updatedStorageFBS = [...prevState.storage_fbs];
            updatedStorageFBS[index] = {
                ...updatedStorageFBS[index],
                accounting_minimum_balance_count: count,
            };

            return {
                ...prevState,
                storage_fbs: updatedStorageFBS
            };
        });
    };

    const checkDuplicatesStorageFbs = (): boolean => {
        if (!settingProduct.storage_fbs || !Array.isArray(settingProduct.storage_fbs) || settingProduct.storage_fbs.length === 0)
            return false;

        const ids = new Set<string>();

        for (const item of settingProduct.storage_fbs) {
            const idCombo = `${item.wildberries_id}-${item.moysklad_id}`;
            if (ids.has(idCombo)) {
                return true;
            }
            ids.add(idCombo);
        }

        return false;
    }

    const checkStorageFBS = (): boolean => {
        if (checkForEmptyStorageFbs()) {
            showError("Ошибка. Требуется указать все склады", inverted);
            return false;
        }

        if (checkDuplicatesStorageFbs()) {
            showError("Ошибка. Дублируются склады, удалите одинаковые значения складов.", inverted);
            return false;
        }

        return true;
    }

    const checkPrices = (): boolean => {
        if (settingProduct.price_before_discounts_id === settingProduct.retail_price_id) {
            showError("Ошибка. Выберите разные цены.", inverted);
            return false;
        }

        if (settingProduct.price_before_discounts_id === '' || settingProduct.retail_price_id === '') {
            showError("Ошибка. Требуется указать все цены", inverted);
            return false;
        }
        return true;
    }


    const checkForEmptyStorageFbs = (): boolean => {
        if (!settingProduct.storage_fbs || !Array.isArray(settingProduct.storage_fbs) || settingProduct.storage_fbs.length === 0)
            return true;

        for (const item of settingProduct.storage_fbs) {
            if (!item.wildberries_id || !item.moysklad_id) {
                return true;
            }
        }

        return false;
    }


    const onSaveSettingOrder = () => {
        putSettingProducts(account_id, activeSettingsId, settingProduct)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    showSuccess("Сохранено", inverted);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    useEffect(() => {
        setTableControls(prevState => ({...prevState, page: 1}))
        handleProductLinks();
    }, [searchText])


    //***************************************  Таймеры начало ***********************************************
    useEffect(() => {
        timersRef.current = timers;

    }, [timers]);

    const startStatusCheckTimer = useCallback((key: string) => {
        const id = setInterval(() => checkStatusProcess(key), 5000);
        setTimers(prevTimers => new Map(prevTimers).set(key, id));
    }, []);

    const stopStatusCheckTimer = useCallback((key: string) => {
        const timer = timersRef.current.get(key);
        if (timer) {
            clearInterval(timer);
            setTimers(prevTimers => {
                const newTimers = new Map(prevTimers);
                newTimers.delete(key);
                return newTimers;
            });
        }
    }, []);


    useEffect(() => {
        return () => {
            timersRef.current.forEach(timer => clearInterval(timer));
        };
    }, []);

    useEffect(() => {
        const timerKeys = [
            'product_wildberries_to_moysklad',
            'price_moysklad_to_wildberries',
            'price_wildberries_to_moysklad',
            'sync_product',
            'FBO_stock_wildberries_to_moySklad',
            'FBS_stock_moysklad_to_wildberries'
        ];

        timerKeys.forEach(key => {
            if (Boolean(settingProduct[key]) && !timersRef.current.has(key)) {
                startStatusCheckTimer(key);
            }
        });
    }, [settingProduct, startStatusCheckTimer]);

    // Запускаем процесс с url
    const handleRunProcess = (url: string) => {
        runProcess(account_id, activeSettingsId, url)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    if (url === 'sync_product' || url === 'product_wildberries_to_moysklad')
                        handleProductLinks();
                    showSuccess('Задача успешно запущена', inverted);
                    updateSettingProduct(url, true);
                    startStatusCheckTimer(url);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    // Проверяем статус процесса
    const checkStatusProcess = (url: string) => {
        getStatusProcess(account_id, activeSettingsId, url)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    updateSettingProduct(url, false);
                    if (url === 'sync_product' || url === 'product_wildberries_to_moysklad') {
                        handleProductLinks();
                    }
                    stopStatusCheckTimer(url);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    };

//***************************************  Таймеры конец ***********************************************

    return (
        <>
            <Sidebar autoClose={false} position='right' isOpen={telegramSettingOpen}
                     onClose={() => setTelegramSettingOpen(false)}
            ><Segment inverted={inverted} style={{borderRadius: 0, minHeight: '100%'}}>
                <Grid verticalAlign='middle'>
                    <GridRow key='0'>
                        <GridColumn floated='left' width={13}><h5>Уведомление об ошибках в телеграм</h5></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <div className='advanced-menu-icon-back'
                                 onClick={() => setTelegramSettingOpen(false)}>
                                <Icon name='arrow right' size='big'/>
                            </div>
                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>

                    {telegramUserNames.map((value) => (
                        <React.Fragment key={value}>
                            <GridRow>
                                <GridColumn width={16}>
                                    <button
                                        onClick={() => {
                                            handleOpenTelegramAccounts(value)
                                        }}
                                        className={inverted ? 'button-link inverted' : 'button-link'}>
                                        Отключить @{value}
                                    </button>
                                </GridColumn>
                            </GridRow>
                            <Divider></Divider>
                        </React.Fragment>
                    ))}

                    {(telegramUserNames.length < settingConnect.telegram_limit_account) && (
                        <React.Fragment key="1">
                            <GridRow>
                                <GridColumn floated='right' width={16}>
                                    <a onClick={() => setTelegramSettingOpen(false)}
                                        href={`tg://resolve?domain=${process.env.REACT_APP_TELEGRAM_DOMAIN}&start=${account_id}_${activeSettingsId}`}
                                       target='_blank'>Подключить нового пользователя</a>
                                </GridColumn>
                            </GridRow>
                            <Divider></Divider>
                        </React.Fragment>
                    )}
                </Grid>

            </Segment></Sidebar>


            <Sidebar autoClose={false} position='right' isOpen={advancedSettingOpen}
                     onClose={() => setAdvancedSettingOpen(false)}
            ><Segment inverted={inverted} style={{borderRadius: 0, minHeight: '100%'}}>
                <Grid verticalAlign='middle'>
                    <GridRow>
                        <GridColumn floated='left' width={13}><h3>Товар</h3></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <div className='advanced-menu-icon-back'
                                 onClick={() => setAdvancedSettingOpen(false)}>
                                <Icon name='arrow right' size='big'

                                />
                            </div>
                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn floated='left' width={13}><strong>Бренд</strong></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <Checkbox slider className={inverted ? 'dark' : ''}
                                      checked={Boolean(settingProduct.brend_show)}
                                      onClick={() => updateSettingProduct('brend_show',
                                          !settingProduct.brend_show)}

                            />
                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn floated='left' width={13}><strong>Цвет</strong></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <Checkbox slider className={inverted ? 'dark' : ''}
                                      checked={Boolean(settingProduct.color_show)}
                                      onClick={() => updateSettingProduct('color_show',
                                          !settingProduct.color_show)}
                            />
                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn floated='left' width={13}><strong>Размер</strong></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <Checkbox slider className={inverted ? 'dark' : ''}
                                      checked={Boolean(settingProduct.size_show)}
                                      onClick={() => updateSettingProduct('size_show',
                                          !settingProduct.size_show)}
                            />
                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn floated='left' width={16}><h3>Склад и остатки</h3></GridColumn>
                    </GridRow>
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn floated='left' width={13}><strong>Учет неснижаемого остатка</strong></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <Checkbox slider className={inverted ? 'dark' : ''}
                                      checked={Boolean(settingProduct.accounting_minimum_balance_show)}
                                      onClick={() => updateSettingProduct('accounting_minimum_balance_show',
                                          !settingProduct.accounting_minimum_balance_show)}
                            />
                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>
                </Grid>

            </Segment></Sidebar>

            <Accordion title='Товары' saveName='AccordionProduct' inverted={inverted}
                       headerContent={
                           <>

                               <div className='buttons-connect'>
                                   <Button inverted={inverted} basic
                                           onClick={() => setAdvancedSettingOpen(true)

                                           }
                                   >Расширенные настройки</Button>
                                   <Button
                                       onClick={onSaveSettingOrder}
                                       primary inverted={inverted}>Сохранить</Button>

                               </div>
                           </>
                       }
            ><Segment inverted={inverted}>
                <Grid verticalAlign='middle' className='segment-grid'>
                    <GridRow>
                        <GridColumn width={2}>
                            <strong>Переносить варианты товаров с Wildberries как модификации в МойСклад</strong>
                        </GridColumn>
                        <GridColumn width={2}>
                            <Checkbox slider className={inverted ? 'dark' : ''}
                                      checked={Boolean(settingProduct.variant_wildberries_to_mod_moysklad)}
                                      onClick={() => updateSettingProduct('variant_wildberries_to_mod_moysklad',
                                          !settingProduct.variant_wildberries_to_mod_moysklad)}/>
                        </GridColumn>
                    </GridRow>
                    {Boolean(settingProduct.variant_wildberries_to_mod_moysklad) && <><Divider></Divider>
                        <GridRow>
                            <GridColumn width={2} className='column-segment-grid'>
                                <strong>Сопоставьте характеристики модификаций</strong>
                            </GridColumn>
                            <GridColumn width={3} className='column-segment-grid'>
                                <p className='subtext'>Wildberries</p>
                                <strong>Размер</strong>
                            </GridColumn>
                            <GridColumn width={3} className='column-segment-grid'>
                                <p className='subtext'>МойСклад</p>
                                <Select
                                    defaultValue={getSelectDefaultValue('compare_characteristics_modifications')}
                                    onSelect={(item => updateSettingProductSelect('compare_characteristics_modifications', item))}
                                    onOpen={() => handleOpenSelectMoySklad('variant/characteristics')}
                                    list={listSelect}
                                ></Select>
                            </GridColumn>
                        </GridRow>
                    </>}
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn width={2}>
                            <strong>Не создавать новый товар и заказ, если товара нет в таблице связей</strong>
                        </GridColumn>
                        <GridColumn width={2}>
                            <Checkbox slider className={inverted ? 'dark' : ''}
                                      checked={Boolean(settingProduct.not_create_new_product_if_not_exist_bind)}
                                      onClick={() => updateSettingProduct('not_create_new_product_if_not_exist_bind',
                                          !settingProduct.not_create_new_product_if_not_exist_bind)}
                            />
                        </GridColumn>
                    </GridRow>


                    {Boolean(settingProduct.brend_show) &&
                        <>
                            <Divider></Divider>
                            <GridRow>
                                <GridColumn width={2} className='column-segment-grid'>
                                    <strong>Бренд</strong>
                                    <p className='subtext'>Зайдите в раздел `Товары и услуги" в МойСклад, создайте
                                        дополнительное поле по типу "строка или текст" и выберите его</p>
                                </GridColumn>
                                <GridColumn width={3} className='column-segment-grid'>

                                    <Select
                                        defaultValue={getSelectDefaultValue('brend')}
                                        onSelect={(item => updateSettingProductSelect('brend', item))}
                                        onOpen={() => handleOpenSelectProductAttributes(['text', 'string'])}
                                        list={listSelect}
                                    ></Select>

                                </GridColumn>
                            </GridRow>
                        </>
                    }
                    {Boolean(settingProduct.size_show) && <>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2} className='column-segment-grid'>
                                <strong>Размер</strong>
                                <p className='subtext'>Зайдите в раздел "Товары и услуги" в МойСклад, создайте
                                    дополнительное поле по типу "строка или текст" и выберите его</p>
                            </GridColumn>
                            <GridColumn width={3} className='column-segment-grid'>

                                <Select
                                    defaultValue={getSelectDefaultValue('size')}
                                    onSelect={(item => updateSettingProductSelect('size', item))}
                                    onOpen={() => handleOpenSelectProductAttributes(['text', 'string'])}
                                    list={listSelect}
                                ></Select>

                            </GridColumn>
                        </GridRow>
                    </>
                    }
                    {Boolean(settingProduct.color_show) && <>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2} className='column-segment-grid'>
                                <strong>Цвет</strong>
                                <p className='subtext'>Зайдите в раздел "Товары и услуги" в МойСклад, создайте
                                    дополнительное поле по типу "строка или текст" и выберите его</p>
                            </GridColumn>
                            <GridColumn width={3} className='column-segment-grid'>

                                <Select
                                    defaultValue={getSelectDefaultValue('color')}
                                    onSelect={(item => updateSettingProductSelect('color', item))}
                                    onOpen={() => handleOpenSelectProductAttributes(['text', 'string'])}
                                    list={listSelect}
                                ></Select>

                            </GridColumn>
                        </GridRow>
                    </>
                    }
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn width={3}>
                            <button
                                onClick={() => {
                                    if (!checkPrices()) return;
                                    setOpenConfirmProductMoyskladToWildberries(true)
                                }}
                                style={{minWidth: "320px", paddingLeft: 0, marginLeft: 0}}
                                disabled={Boolean(settingProduct.product_wildberries_to_moysklad) || !connected}
                                className={inverted ? 'button-link inverted' : 'button-link'}>Перенести товары в
                                МойСклад из Wildberries
                            </button>
                            {Boolean(settingProduct.product_wildberries_to_moysklad) &&
                                <Icon loading name='spinner' inverted={inverted}/>
                            }
                            <Confirm
                                className='modal-dialog'
                                open={openConfirmProductMoyskladToWildberries}
                                content='Вы уверены в том что хотите перенести товары в МойСклад из Wildberries?'
                                cancelButton='Нет'
                                confirmButton='Да'
                                onCancel={() => setOpenConfirmProductMoyskladToWildberries(false)}
                                onConfirm={() => {
                                    setOpenConfirmProductMoyskladToWildberries(false);
                                    handleRunProcess('product_wildberries_to_moysklad')
                                }}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>

            </Segment></Accordion>


            <Accordion title='Склады и остатки' saveName='AccordionStores' inverted={inverted}
                       headerContent={
                           <>
                               <Checkbox className={inverted ? 'dark' : ''} toggle
                                         checked={Boolean(settingProduct.unload_fbs_remains)}
                                         onClick={() => {
                                             if (!settingProduct.unload_fbs_remains) {
                                                 if (!checkStorageFBS()) return;
                                                 if (!checkPrices()) return;
                                                 setOpenConfirmUnloadFbsRemains(true);
                                             } else
                                                 updateSettingProduct('unload_fbs_remains', false);
                                         }}
                               ></Checkbox>
                               <label style={{
                                   color: inverted ? 'white' : '#706f6f'
                               }}>Автоматически выгружать FBS остатки на WB</label>

                               <Confirm
                                   className='modal-dialog'
                                   open={openConfirmUnloadFbsRemains}
                                   content={`Вы уверен, что хотите запустить выгрузку остатков? Остановить процесс и восстановить данные не получиться`}
                                   cancelButton='Нет'
                                   confirmButton='Да'
                                   onCancel={() => {
                                       updateSettingProduct('unload_fbs_remains', false)
                                       setOpenConfirmUnloadFbsRemains(false)

                                   }}
                                   onConfirm={() => {
                                       setOpenConfirmUnloadFbsRemains(false)
                                       //handlePutUnloadFbsRemains(true);
                                       updateSettingProduct('unload_fbs_remains', true);

                                       // handleRunProcess('sync_product')
                                   }}
                               />

                           </>
                       }

            ><Segment inverted={inverted}>
                <Grid verticalAlign='middle'>
                    <GridRow>
                        <GridColumn className='column-segment-grid'>
                            <strong>Склады FBS</strong>
                        </GridColumn>
                        <GridColumn className='column-segment-grid'>
                            <p className='subtext'>Склад Wildberries</p>
                            <Select
                                disabled={settingProduct.unload_fbs_remains || Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) || Boolean(settingProduct.FBS_stock_moysklad_to_wildberries)}
                                defaultValue={getStorageFbsDefaultValue('wildberries', 0)}
                                onSelect={(item: SelectItem | SelectItem[]) => {
                                    if (!Array.isArray(item)) storageFbsSelectItem('wildberries', item, 0)
                                }}
                                onOpen={() => handleOpenSelectWildberries('warehouses')}
                                list={listSelect}
                            ></Select>
                        </GridColumn>
                        <GridColumn style={{minWidth: '270px'}}>
                            <p className='subtext'>Склад МойСклад</p>

                            <Select
                                disabled={settingProduct.unload_fbs_remains || Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) || Boolean(settingProduct.FBS_stock_moysklad_to_wildberries)}
                                defaultValue={getStorageFbsDefaultValue('moysklad', 0)}
                                onSelect={(item: SelectItem | SelectItem[]) => {
                                    if (!Array.isArray(item)) storageFbsSelectItem('moysklad', item, 0)
                                }}
                                onOpen={() => handleOpenSelectMoySklad('store')}
                                list={listSelect}
                            ></Select>


                        </GridColumn>
                        {settingProduct.accounting_minimum_balance_id === 'manual' &&
                            Boolean(settingProduct.accounting_minimum_balance_show) &&
                            <GridColumn style={{minWidth: '100px'}}>
                                <p className='subtext'>неснижаемый остаток</p>
                                <Input value={getStorageFbsAccountingMinimumBalanceValue(0)}
                                       onChange={event => storageFbsChangeAccountingMinimumBalanceCount(Number(event.target.value), 0)}
                                       className='input-number' type='number'></Input>
                            </GridColumn>}

                    </GridRow>

                    {Array.isArray(settingProduct.storage_fbs) && settingProduct.storage_fbs.map((item, index) => {
                        if (index === 0) return null;
                        return (<React.Fragment key={index}>
                            <GridRow>
                                <GridColumn className='column-segment-grid'>

                                </GridColumn>
                                <GridColumn className='column-segment-grid'>
                                    <Select
                                        disabled={settingProduct.unload_fbs_remains || Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) || Boolean(settingProduct.FBS_stock_moysklad_to_wildberries)}
                                        defaultValue={{id: item.wildberries_id, name: item.wildberries_name}}
                                        onSelect={(item: SelectItem | SelectItem[]) => {
                                            if (!Array.isArray(item)) storageFbsSelectItem('wildberries', item, index)
                                        }}
                                        onOpen={() => handleOpenSelectWildberries('warehouses')}
                                        list={listSelect}
                                    ></Select>
                                </GridColumn>
                                <GridColumn style={{minWidth: '270px'}}>

                                    <Select
                                        disabled={settingProduct.unload_fbs_remains || Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) || Boolean(settingProduct.FBS_stock_moysklad_to_wildberries)}
                                        defaultValue={{id: item.moysklad_id, name: item.moysklad_name}}
                                        onSelect={(item: SelectItem | SelectItem[]) => {
                                            if (!Array.isArray(item)) storageFbsSelectItem('moysklad', item, index)
                                        }}
                                        onOpen={() => handleOpenSelectMoySklad('store')}
                                        list={listSelect}
                                    ></Select>


                                </GridColumn>
                                {settingProduct.accounting_minimum_balance_id === 'manual' &&
                                    Boolean(settingProduct.accounting_minimum_balance_show) &&
                                    <GridColumn style={{minWidth: '100px'}}>

                                        <Input value={getStorageFbsAccountingMinimumBalanceValue(index)}
                                               onChange={event => storageFbsChangeAccountingMinimumBalanceCount(Number(event.target.value), index)}
                                               className='input-number' type='number'></Input>
                                    </GridColumn>}
                                {(!settingProduct.unload_fbs_remains && !Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) && !Boolean(settingProduct.FBS_stock_moysklad_to_wildberries)) &&
                                    <GridColumn>
                                        <Icon name="trash alternate outline" size='large' className='icon-delete'
                                              onClick={() => storageFbsRemoveItem(index)}/>
                                    </GridColumn>
                                }
                            </GridRow>

                        </React.Fragment>)
                    })
                    }
                    <GridRow>
                        <GridColumn className='column-segment-grid'>
                        </GridColumn>
                        {(!settingProduct.unload_fbs_remains && !Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) && !Boolean(settingProduct.FBS_stock_moysklad_to_wildberries)) &&
                            <GridColumn className='column-segment-grid'>
                                <button className='button-link'
                                        onClick={storageFbsAddItem}
                                >+ добавить склад
                                </button>
                            </GridColumn>
                        }
                    </GridRow>
                    {/*<GridRow>*/}
                    {/*    <GridColumn width={2} className='column-segment-grid'>*/}
                    {/*        <strong>Склады FBO</strong>*/}
                    {/*    </GridColumn>*/}
                    {/*    <GridColumn width={3} className='column-segment-grid'>*/}
                    {/*        <p className='subtext'>Склад wildberries</p>*/}
                    {/*        <strong>Все склады FBO</strong>*/}
                    {/*    </GridColumn>*/}
                    {/*    <GridColumn width={3} className='column-segment-grid'>*/}
                    {/*        <p className='subtext'>Склад МойСклад</p>*/}
                    {/*        <Select*/}
                    {/*            defaultValue={getSelectDefaultValue('storage_fbo')}*/}
                    {/*            onSelect={(item => updateSettingProductSelect('storage_fbo', item))}*/}
                    {/*            onOpen={() => handleOpenSelectMoySklad('states')}*/}
                    {/*            list={listSelect}*/}
                    {/*        ></Select>*/}
                    {/*    </GridColumn>*/}
                    {/*</GridRow>*/}
                    {/*<Divider></Divider>*/}
                    {/*<GridRow>*/}
                    {/*    <GridColumn width={2} className='column-segment-grid'>*/}
                    {/*        <strong>Склады для возвратов</strong>*/}
                    {/*    </GridColumn>*/}
                    {/*    <GridColumn width={3} className='column-segment-grid'>*/}
                    {/*        <Select*/}
                    {/*            defaultValue={getSelectDefaultValue('storage_return')}*/}
                    {/*            onSelect={(item => updateSettingProductSelect('storage_return', item))}*/}
                    {/*            onOpen={() => handleOpenSelectMoySklad('states')}*/}
                    {/*            list={listSelect}*/}
                    {/*        ></Select>*/}
                    {/*    </GridColumn>*/}
                    {/*</GridRow>*/}
                    {Boolean(settingProduct.accounting_minimum_balance_show) && <>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2} className='column-segment-grid'>
                                <strong>Учет неснижаемого остатка</strong>
                            </GridColumn>
                            <GridColumn width={3} className='column-segment-grid'>

                                <Select
                                    defaultValue={getSelectDefaultValue('accounting_minimum_balance')}
                                    onSelect={(item => updateSettingProductSelect('accounting_minimum_balance', item))}
                                    emptyItem={false}
                                    list={[{id: "", name: "Выключено"},
                                        // {id: "card",  name: "Брать из карточки товара"},
                                        {id: "manual", name: "Указать по складам"}]}

                                ></Select>

                            </GridColumn>
                        </GridRow>
                    </>}
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn width={2} className='column-segment-grid'>
                            <strong>Перенести остатки</strong>
                        </GridColumn>
                        <GridColumn width={3} className='column-segment-grid'>
                            <Select
                                defaultValue={getSelectDefaultValue('transfer_balances')}
                                emptyItem={false}
                                onSelect={(item => updateSettingProductSelect('transfer_balances', item))}
                                list={[{id: "FBO_stock_wildberries_to_moySklad", name: "FBO из Wildberries в МойСклад"},
                                    {id: "FBS_stock_moysklad_to_wildberries", name: "FBS из МойСклад в Wildberries"}]}
                            ></Select>

                            {/*<Select*/}
                            {/*    defaultValue={[{id: "a2", name: "a2aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"}]}*/}
                            {/*    multiselect={true}*/}
                            {/*    list={[{id: "a1", name: "a1"},*/}
                            {/*        {id: "a2", name: "a2aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"}]}*/}
                            {/*></Select>*/}

                        </GridColumn>
                        <GridColumn width={3} className='column-segment-grid'>
                            <button
                                disabled={Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) || Boolean(settingProduct.FBS_stock_moysklad_to_wildberries) || !connected}
                                className={inverted ? 'button-link inverted' : 'button-link'}
                                onClick={() => {
                                    if (settingProduct.transfer_balances_id === 'FBS_stock_moysklad_to_wildberries' && !checkStorageFBS()) return;
                                    if (!checkPrices()) return;
                                    setOpenConfirmProductTransferBalances(true);
                                }}
                            >Выполнить
                            </button>
                            {(Boolean(settingProduct.FBO_stock_wildberries_to_moySklad) || Boolean(settingProduct.FBS_stock_moysklad_to_wildberries)) &&
                                <Icon loading name='spinner' inverted={inverted}/>
                            }
                            <Confirm
                                className='modal-dialog'
                                open={openConfirmProductTransferBalances}
                                content={`Вы уверены в том что хотите перенести остатки ${settingProduct.transfer_balances_name}?`}
                                cancelButton='Нет'
                                confirmButton='Да'
                                onCancel={() => setOpenConfirmProductTransferBalances(false)}
                                onConfirm={() => {
                                    setOpenConfirmProductTransferBalances(false)
                                    handleRunProcess(settingProduct.transfer_balances_id)
                                }}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>

            </Segment></Accordion>


            <Accordion title='Цена' saveName='AccordionPrice' inverted={inverted}
                       headerContent={
                           <>
                               <Checkbox className={inverted ? 'dark' : ''} toggle
                                         checked={Boolean(settingProduct.upload_price_moysklad_to_wildberries)}
                                         onClick={() => {
                                             if (!settingProduct.upload_price_moysklad_to_wildberries) {
                                                 if (!checkPrices()) return;

                                                 updateSettingProduct('upload_price_moysklad_to_wildberries', true);
                                                 //   setOpenConfirmUploadPriceMoyskladToWildberries(true);
                                             } else {
                                                 updateSettingProduct('upload_price_moysklad_to_wildberries', false);
                                             }
                                         }}
                               ></Checkbox>
                               <label style={{
                                   color: inverted ? 'white' : '#706f6f'
                               }}>Автоматически выгружать цены на WB</label>

                               <Confirm
                                   className='modal-dialog'
                                   open={openConfirmUploadPriceMoyskladToWildberries}
                                   content={`Вы уверен, что хотите запустить выгрузку цен? Восстановить данные не получиться. Цены выгрузятся без копеек`}
                                   cancelButton='Нет'
                                   confirmButton='Да'
                                   onCancel={() => setOpenConfirmUploadPriceMoyskladToWildberries(false)}
                                   onConfirm={() => {
                                       setOpenConfirmUploadPriceMoyskladToWildberries(false);

                                   }}
                               />
                           </>
                       }

            ><Segment inverted={inverted}>
                <Grid verticalAlign='middle' className='segment-grid'>
                    <GridRow>
                        <GridColumn width={2}>
                            <strong>Розничная цена</strong>
                        </GridColumn>
                        <GridColumn width={2}>
                            <Select
                                defaultValue={getSelectDefaultValue('retail_price')}
                                onSelect={(item => updateSettingProductSelect('retail_price', item))}
                                onOpen={() => handleOpenSelectMoySklad('price_types')}
                                list={listSelect}
                                disabled={Boolean(settingProduct.upload_price_moysklad_to_wildberries)}
                            ></Select>

                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn width={2}>
                            <strong>Цена со скидкой</strong>
                        </GridColumn>
                        <GridColumn width={2}>
                            <Select
                                defaultValue={getSelectDefaultValue('price_before_discounts')}
                                onSelect={(item => updateSettingProductSelect('price_before_discounts', item))}
                                onOpen={() => handleOpenSelectMoySklad('price_types')}
                                list={listSelect}
                                disabled={Boolean(settingProduct.upload_price_moysklad_to_wildberries)}
                            ></Select>
                        </GridColumn>
                    </GridRow>
                    <Divider></Divider>
                    <GridRow>
                        <GridColumn width={2} className='column-segment-grid'>
                            <strong>Перенести цены</strong>
                        </GridColumn>
                        <GridColumn width={3} className='column-segment-grid'>
                            <Select
                                defaultValue={getSelectDefaultValue('transfer_price')}
                                emptyItem={false}
                                onSelect={(item => updateSettingProductSelect('transfer_price', item))}
                                list={[{id: "price_wildberries_to_moysklad", name: "Из Wildberries в МойСклад"},
                                    {id: "price_moysklad_to_wildberries", name: "Из МойСклад в Wildberries"}]}
                            ></Select>
                        </GridColumn>
                        <GridColumn width={3}>
                            <button style={{paddingLeft: 0, marginLeft: 0}}
                                    className={inverted ? 'button-link inverted' : 'button-link'}
                                    disabled={
                                        Boolean(settingProduct.price_moysklad_to_wildberries || settingProduct.price_wildberries_to_moysklad)
                                        || !connected
                                        || (settingProduct.price_before_discounts_id === settingProduct.retail_price_id)}
                                    onClick={() => {
                                        if (!checkPrices()) return;
                                        setOpenConfirmProductPriceMoyskladToWildberries(true)
                                    }}
                            >Выполнить
                            </button>
                            {Boolean(settingProduct.price_moysklad_to_wildberries || settingProduct.price_wildberries_to_moysklad) &&
                                <Icon loading name='spinner' inverted={inverted}/>
                            }
                            <Confirm
                                className='modal-dialog'
                                open={openConfirmProductPriceMoyskladToWildberries}
                                content={settingProduct.transfer_price_id === 'price_moysklad_to_wildberries'
                                    ? `Вы уверен, что хотите запустить выгрузку цен? Восстановить данные не получиться. Цены выгрузятся без копеек.`
                                    : `Вы уверен, что хотите запустить выгрузку цен? Восстановить данные не получиться. Цены выгрузятся без копеек.`}
                                cancelButton='Нет'
                                confirmButton='Да'
                                onCancel={() => setOpenConfirmProductPriceMoyskladToWildberries(false)}
                                onConfirm={() => {
                                    setOpenConfirmProductPriceMoyskladToWildberries(false)
                                    handleRunProcess(settingProduct.transfer_price_id)
                                }}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Segment></Accordion>

            <Accordion title='Таблица связей товаров' saveName='AccordionConnectProducts' inverted={inverted}
                       headerContent={
                           <>
                               <div className='buttons-connect'>

                                   <Input
                                       icon='search'
                                       placeholder="Поиск"
                                       value={searchText}
                                       onChange={(value) => {
                                           setSearchText(value.target.value);

                                       }}
                                       className='input-search'>

                                   </Input>
                                   <Button
                                       icon='telegram plane'
                                       inverted={inverted}

                                       onClick={() => handleOpenTelegramAccounts()}
                                   />


                                   <Button
                                       icon='sync'
                                       inverted={inverted}
                                       disabled={Boolean(settingProduct.sync_product)}
                                       onClick={() => {
                                           if (tableProductLimitsSort.tab !== 'products') {
                                               handleProductLinks();
                                               showSuccess("Обновленно", inverted);
                                               return;
                                           }
                                           setOpenConfirmSyncProduct(true)
                                       }}
                                   />


                                   <ButtonFilter
                                       tableProductLimitsSort={tableProductLimitsSort}
                                       onSelect={(item) => setTableProductLimitsSort(prevState => ({
                                           ...prevState,
                                           status: item
                                       }))}
                                   />

                                   {Boolean(settingProduct.sync_product) &&
                                       <Icon loading name='spinner' inverted={inverted}/>
                                   }
                                   <Confirm
                                       className='modal-dialog'
                                       open={openConfirmSyncProduct}
                                       content={`Вы уверены в том что хотите Синхронизировать товар?`}
                                       cancelButton='Нет'
                                       confirmButton='Да'
                                       onCancel={() => setOpenConfirmSyncProduct(false)}
                                       onConfirm={() => {
                                           setOpenConfirmSyncProduct(false)
                                           handleRunProcess('sync_product')
                                       }}
                                   />
                                   {/*<Button basic inverted={inverted} icon='refresh' onClick={handleProductLinks}></Button>*/}
                               </div>
                           </>
                       }

            >

                <TableProductLinks
                    data={tableProductLimitsData}
                    TableControls={TableControls}
                    onActiveMenuItem={(activeItem) => {
                        setTableProductLimitsSort(prevState => ({...prevState, tab: activeItem, status: 'ALL'}))
                    }}
                    setPage={(value) =>
                        setTableControls(prevState => ({...prevState, page: value}))
                    }
                    setPerPage={(value) =>
                        setTableControls(prevState => ({...prevState, perPage: value}))
                    }
                ></TableProductLinks>
                {tableProductLimitsSort.tab === 'products' &&
                    <p style={{color: '#a7abac'}}>Дата последней синхронихации
                        товаров {settingProduct.last_sync ? formatDateUTC(settingProduct.last_sync) : '-'}</p>
                }
                {tableProductLimitsSort.tab === 'balances' &&
                    <p style={{color: '#a7abac'}}>Дата последней синхронихации
                        остатков {settingProduct.last_balances_send ? formatDateUTC(settingProduct.last_balances_send) : '-'}</p>
                }
                {tableProductLimitsSort.tab === 'prices' &&
                    <p style={{color: '#a7abac'}}>Дата последней синхронихации
                        цен {settingProduct.last_prices_send ? formatDateUTC(settingProduct.last_prices_send) : '-'}</p>
                }
            </Accordion>

            <div style={{height: "200px"}}>
            </div>
        </>
    )
}