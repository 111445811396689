import axios from 'axios';
import {ActiveMenuItemTableProductLinks, SettingConnect} from "./types";


export const getAccountId = async (contextKey: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/context/${contextKey}`);
};

export const getListSettings = async (account_id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/list_settings`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const getSettingConnect = async (account_id: string, setting_id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/setting_connect/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const getLogError = async (account_id: string, setting_id: string, params: any) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/log/error/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params
        });
};

export const getLogUser = async (account_id: string, setting_id: string, params: any) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/log/user/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params
        });
};

export const saveSettingConnect = async (account_id: string, setting_id: string, data: SettingConnect) => {
        return axios.put(`${process.env.REACT_APP_BACKEND}/api/setting_connect/${setting_id}`, data,{
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },

        });
};

export const disconnectSettingConnect = async (account_id: string, setting_id: string) => {
        return axios.delete(`${process.env.REACT_APP_BACKEND}/api/setting_connect/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
        });
};

export const getSettingOrder = async (account_id: string, setting_id: string, type: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/setting_order/${setting_id}/${type}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const getSettingCommissionerReport = async (account_id: string, setting_id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/setting_setting_commissioner_report/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const putSettingOrder = async (account_id: string, setting_id: string, type: string, data: any) => {
        return axios.put(`${process.env.REACT_APP_BACKEND}/api/setting_order/${setting_id}/${type}`, data, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const putSettingCommissionerReport = async (account_id: string, setting_id: string, data: any) => {
        return axios.put(`${process.env.REACT_APP_BACKEND}/api/setting_setting_commissioner_report/${setting_id}`, data, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const getCustomerorderAttributesRequired = async (account_id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/customerorder/attributes/required`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const getCustomerorderAttributesRequiredValues = async (account_id: string, id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/customerorder/attributes/required/values/${id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const getField = async (account_id: string, url: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/${url}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const getOrganizationAccount = async (account_id: string, id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/organization_account/${id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const getSettingProducts = async (account_id: string, setting_id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/setting_products/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const runProcess = async (account_id: string, setting_id: string, url: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/run/${url}/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const runProcessGetShipments = async (account_id: string, setting_id: string, params: any) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/run/GetShipments/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params

        });
};

export const runProcessLoadCommissionerReport = async (account_id: string, setting_id: string, params: any) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/run/load_commissioner_report/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params

        });
};

export const getStatusProcess = async (account_id: string, setting_id: string, url: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/status/${url}/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};


export const getWarehouses = async (account_id: string, url:string, setting_id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/wildberries/${url}/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const getCounterparty = async (account_id: string, text:string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/counterparty/${text}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const getContracts = async (account_id: string, agentId:string, organizationId:string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/contract/${agentId}/${organizationId}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};



export const getLogCommissionerReport = async (account_id: string, setting_id: string) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/log_commissioner_report/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const getOrDeleteTelegramAccounts = async (account_id: string, setting_id: string, deleteUserName: string = '') => {

        if (deleteUserName) {
                return axios.delete(`${process.env.REACT_APP_BACKEND}/api/delete_telegram_accounts/${setting_id}/${deleteUserName}`, {
                        headers: {
                                'Authorization': `Bearer ${account_id}`
                        }
                });
        }

        return axios.get(`${process.env.REACT_APP_BACKEND}/api/get_telegram_accounts/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};




export const getProductLinks = async (account_id: string, setting_id: string, page: number, perPage: number = 50, tab: ActiveMenuItemTableProductLinks = 'products', status: string = 'ALL', searchText: string = '') => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/product_links/${setting_id}`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params: {
                        page,
                        perPage,
                        tab,
                        status,
                        searchText
                },

        });
};


export const putSettingProducts = async (account_id: string, setting_id: string, data: any) => {
        return axios.put(`${process.env.REACT_APP_BACKEND}/api/setting_products/${setting_id}`, data,{
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};



export const putUnloadFbsRemains = async (account_id: string, setting_id: string, enable: number) => {
        return axios.put(`${process.env.REACT_APP_BACKEND}/api/unload_fbs_remains/${setting_id}/${enable}`, [],{
                headers: {
                        'Authorization': `Bearer ${account_id}`
                }
        });
};

export const getCustomerOrderAttributes = async (account_id: string, sort: string[]) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/customerorder/attributes`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params: {
                        sort
                },
        });
};

export const getProductAttributes = async (account_id: string, sort: string[]) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/product/attributes`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params: {
                        sort
                },
        });
};

export const getCommissionReportinAttributes = async (account_id: string, sort: string[]) => {
        return axios.get(`${process.env.REACT_APP_BACKEND}/api/moysklad/commissionreportin/attributes`, {
                headers: {
                        'Authorization': `Bearer ${account_id}`
                },
                params: {
                        sort
                },
        });
};

