import React, {useState, useEffect} from 'react';
import {Dropdown} from 'semantic-ui-react';
import './ButtonFilter.scss'
import {TableProductLimitsSort} from "../../types";


interface ButtonFilterProps {
    onSelect?: (item: string) => void;
    tableProductLimitsSort: TableProductLimitsSort
}

interface ButtonFilterData {
    selectItem: 'ALL' | 'OK' | 'conflict' | 'not_found_product'|'error'|'error_price'|'cannot_export'|'no_answer'|'countNoDiscound';
    text: string,
    count: number
}

export const ButtonFilter = ({
                                 tableProductLimitsSort,
                                 onSelect = (item: string) => {
                                 }
                             }: ButtonFilterProps) => {


    const [data, setData] = useState<ButtonFilterData[]>([])



    useEffect(() => {

        switch (tableProductLimitsSort.tab) {

            case "products":
                if (tableProductLimitsSort.productCount) {
                    setData([{
                        selectItem: "ALL",
                        text: 'Все товары',
                        count: tableProductLimitsSort.productCount.countAll
                    },
                    {
                        selectItem: "OK",
                        text: 'Товар связан',
                        count: tableProductLimitsSort.productCount.countOK
                    },
                    {
                        selectItem: "conflict",
                        text: 'Конфликт товара',
                        count: tableProductLimitsSort.productCount.countConflict
                    },

                    {
                        selectItem: "not_found_product",
                        text: 'Товар не найден',
                        count: tableProductLimitsSort.productCount.countNotFoundProduct
                    }]);
                } else setData([]);

                break

            case  "balances":
                if (tableProductLimitsSort.balancesCount) {
                    setData([{
                        selectItem: "ALL",
                        text: 'Все товары',
                        count: tableProductLimitsSort.balancesCount.countAll
                    },
                        {
                            selectItem: "OK",
                            text: 'Успешно',
                            count: tableProductLimitsSort.balancesCount.countOK
                        },
                        {
                            selectItem: "error",
                            text: 'Ошибка',
                            count: tableProductLimitsSort.balancesCount.countError
                        }]);
                } else setData([]);
                break

            case "prices":
                if (tableProductLimitsSort.pricesCount) {
                    setData([{
                        selectItem: "ALL",
                        text: 'Все товары',
                        count: tableProductLimitsSort.pricesCount.countAll
                    },
                        {
                            selectItem: "OK",
                            text: 'Успешно',
                            count: tableProductLimitsSort.pricesCount.countOK
                        },
                        {
                            selectItem: "countNoDiscound",
                            text: 'Выгрузка без скидки',
                            count: tableProductLimitsSort.pricesCount.countNoDiscound
                        },
                        {
                            selectItem: "cannot_export",
                            text: 'Выгрузка невозможна',
                            count: tableProductLimitsSort.pricesCount.countCannotExport
                        },
                        {
                            selectItem: "error_price",
                            text: 'Ошибка цены',
                            count: tableProductLimitsSort.pricesCount.countErrorPrice
                        },
                        {
                            selectItem: "error",
                            text: 'Ошибка',
                            count: tableProductLimitsSort.pricesCount.countErrorAny
                        },

                    ]);
                } else setData([]);
                break

            default:
                setData([]);
                break
        }


    }, [tableProductLimitsSort.productCount, tableProductLimitsSort.pricesCount, tableProductLimitsSort.balancesCount])

    return (
        <Dropdown
            icon='filter'
            button
            //pointing='right'
            direction='left'
            className='icon'
        >
            <Dropdown.Menu>

            {data.map((item, index) => (
                <Dropdown.Item
                    key={index}
                    onClick={() => onSelect(item.selectItem)}
                >
                    <div className="dropdown-item-content">
                        {item.text} <span
                        className="item-count">({item.count})</span>
                    </div>
                </Dropdown.Item>
            ))}



            </Dropdown.Menu>
        </Dropdown>
    );
};