import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface GlobalContextType {
    inverted: boolean;
    toggleInverted: () => void;
    activeSettingsId: string;
    setActiveSettingsId: (id: string) => void;
    account_id: string,
    setAccount_id: (account_id: string) => void;
    connected: boolean;
    setConnected: (connect: boolean) => void;
}

// Создаем контекст с начальным значением
const GlobalContext = createContext<GlobalContextType>({
    inverted: false,
    toggleInverted: () => {},
    activeSettingsId: '',
    setActiveSettingsId: () => {},
    account_id: '',
    setAccount_id: (account_id: string) => {},
    connected: false,
    setConnected: (connected: boolean) => {}
});

export const useGlobalContext = () => useContext(GlobalContext);

interface GlobalProviderProps {
    children: ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const [inverted, setInverted] = useState<boolean>(localStorage.getItem('inverted') === 'true' ?? false);
    const [activeSettingsId, _setActiveSettingsId] = useState('');
    const [account_id, _setAccount_id] = useState('');
    const [connected, _setConnected] = useState(false);

    const toggleInverted = () => {
        setInverted(currentTheme => !currentTheme);
    };

    useEffect(() => {
        localStorage.setItem('inverted', inverted ? 'true' : 'false');
    }, [inverted]);

    const setActiveSettingsId = (id: string) => {
        _setActiveSettingsId(id);
    };

    useEffect(() => {
      //  localStorage.setItem('inverted', inverted ? 'true' : 'false');
    }, [activeSettingsId]);

    const setAccount_id = (id: string) => {
        _setAccount_id(id);
    };

    useEffect(() => {
        //  localStorage.setItem('inverted', inverted ? 'true' : 'false');
    }, [account_id]);

    const setConnected = (connected: boolean) => {
        _setConnected(connected);
    };

    return (
        <GlobalContext.Provider value={{ inverted, toggleInverted, activeSettingsId, setActiveSettingsId, account_id, setAccount_id, connected, setConnected }}>
            {children}
        </GlobalContext.Provider>
    );
};
