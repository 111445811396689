import React, {useEffect, useState} from "react";
import Accordion from "./Accordion/Accordion";
import {useGlobalContext} from "./GlobalProvider";
import {Button, Dropdown, DropdownProps, Grid, Pagination, Table} from "semantic-ui-react";
import {getLogError} from "../requests";
import {ApiResponse, LogErrorItem, LogErrorResponse} from "../types";
import {formatDateUTC, showError} from "../tools";


const perPageOptions = [
    {key: '10', value: 10, text: '10'},
    {key: '20', value: 20, text: '20'},
    {key: '50', value: 50, text: '50'},
    {key: '100', value: 100, text: '100'}
];


export const LogErrors = () => {
    const [data, setData] = useState<LogErrorItem[]>([]);
    const [page, setPage] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);

    const {
        inverted,
        activeSettingsId,
        account_id,
    } = useGlobalContext();


    const loadLog = () => {
        const params = {
            page,
            perPage,
        }
        getLogError(account_id, activeSettingsId, params)
            .then(value => {
                const response: ApiResponse<LogErrorResponse> = value.data;
                if (response.data && response.success) {
                    const data = response.data;
                    setData(data.items);
                    setPage(data.page);
                    setPerPage(data.perPage);
                    setTotalPages(data.totalPages);
                } else
                    showError('Ошибка ответа получения лога', false);
            })
            .catch(error => {
                showError('Ошибка запроса получения лога', false);
            });
    }

    useEffect(() => {
        if (!activeSettingsId || !account_id) return;
        loadLog();
        // eslint-disable-next-line
    }, [activeSettingsId, account_id, page, perPage])


    return (<div style={{marginBottom: "250px"}}>
        <Accordion
            title='Журнал ошибок'
            inverted={inverted}
            accordion={false}
            headerContent={
                <div className='buttons-connect'>
                    <Button
                        onClick={loadLog}
                        inverted={inverted} basic
                        icon='sync'
                        color='blue'></Button>
                </div>
            }
        >
            <Table celled inverted={inverted}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={2}>Дата</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Событие</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Команда</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Файл</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Ошибка</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Код</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body className='table-body-word-wrap'>
                    {data.map((item) => (
                        <Table.Row key={item.id}>
                            <Table.Cell>{formatDateUTC(item.updated_at)}</Table.Cell>
                            <Table.Cell>{item.action}</Table.Cell>
                            <Table.Cell>{item.command}</Table.Cell>
                            <Table.Cell>{item.file}:{item.number_string}</Table.Cell>
                            <Table.Cell>{item.error}</Table.Cell>
                            <Table.Cell>{item.code}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>

            <Grid>
                <Grid.Row>
                    <Grid.Column width={8} textAlign="left">
                        <Pagination
                            activePage={page}
                            totalPages={totalPages}
                            onPageChange={(e, data: DropdownProps) => setPage(Number(data.activePage))}/>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="right">
                        <Dropdown
                            selection
                            compact
                            value={perPage}
                            options={perPageOptions}
                            onChange={(e, data: DropdownProps) => {
                                setPerPage(Number(data.value));
                                setPage(1);
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Accordion>
    </div>)
}