import React, {useState, useRef, useEffect} from "react";
import './Select.scss';

export type SelectItem = {
    id: string;
    name: string;
};

type SelectProps = {
    list?: SelectItem[];
    emptyItem?: boolean;
    onSelect?: (item: SelectItem[] | SelectItem) => void;
    onTextEnter?: (text: string) => void;
    onOpen?: (text: string) => void;
    multiselect?: boolean;
    maxElement?: number;
    defaultValue?: SelectItem | SelectItem[];
    style?: React.CSSProperties;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    width?: number;
};

export const Select = ({
                           list, emptyItem = true, onSelect = () => {
    }, onTextEnter, multiselect = false, onOpen = () => {
    }, defaultValue, maxElement = 0, style, className, placeholder = "Выберите элемент", disabled = false, width = 240
                       }: SelectProps) => {

    const [localList, setLocalList] = useState<SelectItem[]>([])
    const [isOpen, setIsOpen] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [viewText, setViewText] = useState("");
    const [filteredItems, setFilteredItems] = useState<SelectItem[]>([{id: "", name: "..."}]);
    const containerRef = useRef<HTMLDivElement>(null);
    const multiSelectRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [containerHeight, setContainerHeight] = useState(0);
    const [multiSelectHeight, setMultiSelectHeight] = useState(0);
    const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);

    // Для получения размер div select-container что бы установить top выподающему списку
    useEffect(() => {
        if (containerRef.current) {
            setContainerHeight(containerRef.current.offsetHeight - 2);
        }

        if (multiselect && multiSelectRef.current) {
            setMultiSelectHeight(multiSelectRef.current.offsetHeight);
        }
    }, [selectedItems]);



    // Выбрали элемент из списка
    const handleSelect = (item: SelectItem) => {
        if (disabled) return;
        if (multiselect) {
            if (item.id === "" && item.name === "...") {
                setSelectedItems([]);
                setViewText('');
                onSelect([]);
                return;
            }
            if (maxElement !== 0 && selectedItems.length >= maxElement) return;
            let items: SelectItem[];
            if (selectedItems.some(selected => selected.id === item.id)) {
                items = selectedItems.filter(selected => selected.id !== item.id);
                setSelectedItems(items);
            } else {
                items = [...selectedItems, item];
                setSelectedItems(items);

            }
            setViewText('');
            onSelect(items);

        } else {
            setIsOpen(false);
            if (item.id === "" && item.name === "...") {
                setViewText("");
                onSelect({id: '', name: ''});
            } else {
                setViewText(item.name);
                onSelect(item);
            }
        }
    };


    // Ввести строку для фильтрации списка
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        const val = event.target.value;
        setFilterText(val);
        setViewText(val);
        if (onTextEnter) onTextEnter(val);
        if (list && !multiselect && !val) {
            onSelect({id: '', name: ''});
        }
    };

    // Удалить из мультисписка элемент
    const onClickMultiSelectedItem = (item: SelectItem) => {
        if (multiselect) {
            if (selectedItems.some(selected => selected.id === item.id)) {
                const items = selectedItems.filter(selected => selected.id !== item.id)
                setSelectedItems(items);
                onSelect(items);
            }
        }
    }

    const setOpen = (val: boolean) => {
        if (disabled) return;
        if (val && inputRef.current) {
            inputRef.current.select();
        }
        setIsOpen(val)
    }

    // Закрытия списка когда щелкнули в другое место
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [containerRef]);


    // Список открыт, устанавливаем list
    useEffect(() => {
        if (isOpen && list) {
            setLocalList(list);

        } else if (
            list &&
            !multiselect &&
            !viewText &&
            !emptyItem &&
            (!defaultValue ||
                (!Array.isArray(defaultValue) && !defaultValue.id && !defaultValue.name)))  {
            const item = list[0];

            setViewText(item.name);
            onSelect(item);
        }

    }, [list, isOpen, viewText, multiselect, defaultValue, emptyItem])

    // Фильтрация списка что бы не отображались элементы которые выбраны
    useEffect(() => {
        if (!isOpen) return;
        let newFilteredItems: SelectItem[] = [];
        if (localList.length > 0) {
            newFilteredItems = localList.filter(item => {
                const isFilteredByText = item.name.toLowerCase().includes(filterText.toLowerCase());
                const isNotSelected = !selectedItems.some(selected => selected.id === item.id);
                return multiselect ? isFilteredByText && isNotSelected : isFilteredByText;
            });
        }
        //   console.log("newFilteredItems", newFilteredItems);
        if (emptyItem)
            setFilteredItems([{id: "", name: "..."}, ...newFilteredItems]);
        else if (!multiselect)
            setFilteredItems(newFilteredItems);

    }, [filterText, selectedItems, multiselect, localList]);

    // Установка значений по умолчанию
    useEffect(() => {
        if (defaultValue) {
            if (multiselect && Array.isArray(defaultValue)) {
                setSelectedItems(defaultValue);
            } else if (!multiselect && defaultValue && 'name' in defaultValue && (!onTextEnter || !viewText)) {
                setViewText(defaultValue.name || ''); // Запасное значение
            }
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        if (isOpen) {
            setFilterText('');
            onOpen(viewText);
        }
        // eslint-disable-next-line
    }, [isOpen])


    return (
        <div ref={containerRef} className={`select-container ${className || ''}`}
             title={viewText}
             style={{
                 ...style,
                 opacity: disabled ? 0.45 : 1, // Применяем различную прозрачность в зависимости от состояния disabled
                 width: width
             }}>


            {multiselect ? (
                <div className="multi-select-input" ref={multiSelectRef}>
                    {selectedItems.map((item, index) => {
                        if (maxElement === 0 || index < maxElement) {
                            return (
                                <div key={item.id}
                                     className="selected-item"
                                     onClick={() => onClickMultiSelectedItem(item)}
                                     style={{ pointerEvents: disabled ? 'none' : undefined }}>
                                    {item.name}
                                </div>
                            );
                        }
                        // Если условие не выполняется, не рендерим элемент
                        return null;
                    })}


                    <input
                        className="search-input"
                        placeholder={placeholder}
                        ref={inputRef}
                        value={viewText}
                        onClick={() => setOpen(true)}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                </div>
            ) : (
                <input
                    style={{whiteSpace: 'pre-wrap'}}
                    ref={inputRef}
                    className='single-input'
                    placeholder={placeholder}
                    onClick={() => {setOpen(true)}}
                    value={viewText}
                    onChange={handleChange}
                    disabled={disabled}
                />


            )}
            <div className="select-arrow-div" style={{height: `${multiselect ? multiSelectHeight : containerHeight}px`}}
                 onClick={() => {setOpen(!isOpen)}}>
                <div className={`select-arrow ${isOpen ? 'open' : ''}`}></div>
            </div>
            {isOpen && (
                <div className='select-dropdown' style={{
                    top: `${multiselect ? multiSelectHeight : containerHeight}px`,
                    width: width
                }}>
                    {filteredItems.map((item, index) => (
                        <div key={item.id ? item.id : `unique-${index}`}
                             className="dropdown-item"
                             onClick={() => handleSelect(item)}
                             style={{whiteSpace: 'pre-wrap', pointerEvents: disabled ? 'none' : undefined}}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
