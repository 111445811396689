import React, { FC } from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface OkModalProps {
    content: string | React.ReactNode;
    okButtonClick: () => void;
    className?: string;  // Добавляем возможность передать className
}

export const OkModal: FC<OkModalProps> = ({ content, okButtonClick, className }) => {
    return (
        <Modal
            open={true}
            onClose={okButtonClick}
            size="tiny"
            className={className}  // Передаем className в Modal
        >
            <Modal.Content>
                {content}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={okButtonClick} primary>
                    ОК
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
