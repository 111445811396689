import React, {useState, useEffect} from 'react';

import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './App.scss';



import {Main} from './components/Main'
import {GlobalProvider} from './components/GlobalProvider'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ToastContainer} from "react-toastify";




function App() {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        let win = window.parent;
        let scrollHeight = 15000; //document.documentElement.scrollHeight-20;
        if (scrollHeight !== height) {
            setHeight(scrollHeight);
            const sendObject = {
                height: scrollHeight,
            };
            win.postMessage(sendObject, '*');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ToastContainer />
            <GlobalProvider>
                <div className="App">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Main/>}/>
                        </Routes>
                    </BrowserRouter>
                </div>
            </GlobalProvider>
        </>
    );
}

export default App;
