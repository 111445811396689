import Accordion from "../Accordion/Accordion";
import {Button, Checkbox, Confirm, Divider, Grid, GridColumn, GridRow, Icon, Segment} from "semantic-ui-react";
import {useGlobalContext} from "../GlobalProvider";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Sidebar} from "../Sidebar/Sidebar";
import {Select, SelectItem} from "../Select/Select";
import DatePicker from 'react-datetime-picker';
import {parseISO, subMonths, isAfter} from 'date-fns';

import {
    Field,
    SettingOrderFBS,
    ApiResponse,
    SyncOrderField,
    SyncOrderAditionalField
} from "../../types";
import {OrdersRequiredAttributesForm} from "./OrdersRequiredAttributesForm";
import {
    getContracts,
    getCounterparty, getCustomerOrderAttributes,
    getField,
    getOrganizationAccount, getStatusProcess, putSettingOrder, runProcessGetShipments, runProcessLoadCommissionerReport,
} from "../../requests";
import {formatDate, monthDiff, showError, showSuccess} from "../../tools";
import {OkModal} from "../OkModal";


type OrdersFBSProps = {
    settingOrder: SettingOrderFBS;
    setSettingOrder: (value: React.SetStateAction<SettingOrderFBS>) => void;
}


const syncOrderFieldData: SyncOrderField[] = [

    {show: 'reservation_show', name: 'Резервирование товаров', divider: true, checked: 'reservation'},
    // {show: 'currency_show', name: 'Валюта', divider: true, selectKey: 'currency', url:"currency"},
    {show: 'sales_channel_show', name: 'Канал продаж', divider: true, selectKey: 'sales_channel', url: "saleschannel"},
    {show: 'project_show', name: 'Проект', divider: true, selectKey: 'project', url: "project"},
    {show: 'employee_show', name: 'Сотрудник', divider: true, selectKey: 'employee', url: "employee"},
    {show: 'group_show', name: 'Отдел', divider: true, selectKey: 'group', url: "group"},
]

const syncOrderAditionalField: SyncOrderAditionalField[] = [

    {
        show: 'type_delivery_show',
        name: 'Тип доставки',
        selectKey: 'type_delivery',
        sort: ['string', 'text'],
        subText: "Создайте в документе \"Заказ покупателя\" в МойСклад дополнительное поле по типу \"строка или текст\" и выберите его"
    },
    {
        show: 'type_product_show',
        name: 'Тип товара',
        selectKey: 'type_product',
        sort: ['string', 'text'],
        subText: "Создайте в документе \"Заказ покупателя\" в МойСклад дополнительное поле по типу \"строка или текст\" и выберите его"
    },
    // {show: 'commission_show', name: 'Комиссия - Wildberries', selectKey: 'commission_wildberries', sort: ['string', 'text'], subText:"Тип поля строка или текст"},
    // {show: 'commission_show', name: 'Комиссия - Логистики', selectKey: 'commission_logistics', sort: ['string', 'text'], subText:"Тип поля строка или текст"},
    // {show: 'commission_show', name: 'Комиссия - Штрафы', selectKey: 'commission_fine', sort: ['string', 'text'], subText:"Тип поля строка или текст"},

]

const showPanelData = [
    {name: 'Резервирование товаров', field: 'reservation_show', header: false},
    // {name: 'Валюта', field: 'currency_show', header: false},
    {name: 'Канал продаж', field: 'sales_channel_show', header: false},
    {name: 'Проект', field: 'project_show', header: false},
    {name: 'Сотрудник', field: 'employee_show', header: false},
    {name: 'Отдел', field: 'group_show', header: false},
    {name: 'Счет организации', field: 'organization_account_show', header: false},
    {name: 'Тип нумерации заказов', field: 'order_numbering_type_show', header: false},
    {name: 'Дополнительная информация', field: 'dopinfo', header: true},
    {name: 'Тип доставки', field: 'type_delivery_show', header: false},
    {name: 'Тип товара', field: 'type_product_show', header: false},
    // {name: 'Комиссия', field: 'commission_show', header: false},

];


export const OrdersFBS = ({settingOrder, setSettingOrder}: OrdersFBSProps) => {
    const {inverted, account_id, activeSettingsId, connected} = useGlobalContext();
    const [advancedSettingOpen, setAdvancedSettingOpen] = useState(false);
    const [listSelect, setListSelect] = useState<SelectItem[]>([]);
    const [timers, setTimers] = useState<Map<string, NodeJS.Timeout>>(new Map());
    const timersRef = useRef<Map<string, NodeJS.Timeout>>(timers);
    const [openModal, setOpenModal] = useState(false);
    const [openConfirmGetShipments, setOpenConfirmGetShipments] = useState(false);
    const [countSuccess, setCountSuccess] = useState(0);

    const updateSettingOrderFBO = (key: string, value: any) => {
        setSettingOrder(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const updateSettingOrderSelect = (key: string, value: any) => {
        setSettingOrder(prev => ({
            ...prev,
            [key + "_id"]: value.id,
            [key + "_name"]: value.name,
        }))
    }


    const getSelectDefaultValue = (key: string): Field => {
        if (!settingOrder) return {id: '', name: ''};
        return {
            id: settingOrder[key + '_id'],
            name: settingOrder[key + '_name']
        };
    };


    const onSaveSettingOrder = () => {

        putSettingOrder(account_id, activeSettingsId, "FBS", settingOrder)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    showSuccess("Сохранено", inverted);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const getContract = () => {
        setListSelect([]);
        if (!settingOrder.counterparty_id || !settingOrder.organization_id) return;
        getContracts(account_id, settingOrder.counterparty_id, settingOrder.organization_id)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleOpenSelect = (url: string) => {
        setListSelect([]);
        getField(account_id, url)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleTextChange = (text: string) => {

        if (!text) {
            setListSelect([]);
            return;
        }

        getCounterparty(account_id, text)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleOrganizationAccount = () => {
        if (!settingOrder.organization_id) return;
        setListSelect([]);
        getOrganizationAccount(account_id, settingOrder.organization_id)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    console.error("handleOrganizationAccount", data.error)
                }
            })
            .catch(error => {
                console.error("handleOrganizationAccount", error)
            });
    }

    const handleOpenSelectCustomerOrderAttributes = (sort: string[]) => {
        setListSelect([]);

        getCustomerOrderAttributes(account_id, sort)
            .then(value => {

                const data: ApiResponse<SelectItem[]> = value.data;
                if (data.success && data.data) {
                    setListSelect(data.data);
                } else {
                    if (data.error)
                        showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

//***************************************  Таймеры начало ***********************************************
    const startStatusCheckTimer = useCallback((key: string) => {
        const id = setInterval(() => checkStatusProcess(key), 5000);
        setTimers(prevTimers => new Map(prevTimers).set(key, id));
    }, []);

    const stopStatusCheckTimer = useCallback((key: string) => {
        const timer = timersRef.current.get(key);
        if (timer) {
            clearInterval(timer);
            setTimers(prevTimers => {
                const newTimers = new Map(prevTimers);
                newTimers.delete(key);
                return newTimers;
            });
        }
    }, []);

    useEffect(() => {
        timersRef.current = timers;
    }, [timers]);

    useEffect(() => {
        return () => {
            timersRef.current.forEach(timer => clearInterval(timer));
        };
    }, []);

    useEffect(() => {
        const timerKeys = [
            'GetShipments'
        ];

        timerKeys.forEach(key => {

            if (Boolean(settingOrder[key]) && !timersRef.current.has(key)) {
                startStatusCheckTimer(key);
            }
        });
    }, [settingOrder, startStatusCheckTimer]);

    // Запускаем процесс с url
    const handleRunProcess = (url: string) => {
        const manualShipmentDateStr = settingOrder.manual_shipment_date;
        const manualShipmentDate = parseISO(manualShipmentDateStr);
        const oneMonthAgo = subMonths(new Date(), 1);

        if (!isAfter(manualShipmentDate, oneMonthAgo)) {
            showError('Дата не должна быть больше одного месяца', inverted)
            return;
        }

        const params = {
            manual_shipment_date: settingOrder.manual_shipment_date,
        }

        runProcessGetShipments(account_id, activeSettingsId, params)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    showSuccess('Задача успешно запущена', inverted);
                    updateSettingOrderFBO(url, true);
                    startStatusCheckTimer(url);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    // Проверяем статус процесса
    const checkStatusProcess = (url: string) => {
        getStatusProcess(account_id, activeSettingsId, url)
            .then(value => {
                const data = value.data;
                if (data.success) {

                    updateSettingOrderFBO(url, false);
                    stopStatusCheckTimer(url);
                    if (url === 'GetShipments' && data?.countSuccess) {
                        setCountSuccess(data.countSuccess);
                        setOpenModal(true);
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    };

    //***************************************  Таймеры конец ***********************************************
    return (
        <>
            {openModal &&
                <OkModal
                    className='modal-dialog'
                    content={
                        <>
                            <p style={
                                {
                                    marginBottom: 0,
                                    fontWeight: 'bold',
                                }}>Загрузка завершена</p>
                            <p>Отгрузок создано: {countSuccess}</p>
                        </>}
                    okButtonClick={() => {
                        setOpenModal(false);
                    }}
                />
            }
            <Sidebar autoClose={false} position='right' isOpen={advancedSettingOpen}
                     onClose={() => setAdvancedSettingOpen(false)}
            ><Segment inverted={inverted} style={{borderRadius: 0, minHeight: '100%'}}>
                <Grid verticalAlign='middle'>
                    <GridRow>
                        <GridColumn floated='left' width={13}><h3>Системные данные</h3></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <div className='advanced-menu-icon-back'
                                 onClick={() => setAdvancedSettingOpen(false)}>
                                <Icon name='arrow right' size='big'/>
                            </div>
                        </GridColumn>
                    </GridRow>

                    {showPanelData &&
                        showPanelData.map((value) => (
                            <React.Fragment key={value.field}>
                                <Divider/>
                                <GridRow>
                                    {!value.header ? <>
                                            <GridColumn floated='left' width={13}>
                                                <strong>{value.name}</strong>
                                            </GridColumn>
                                            <GridColumn floated='right' width={3}>
                                                <Checkbox
                                                    slider
                                                    className={inverted ? 'dark' : ''}
                                                    checked={Boolean(settingOrder[value.field])}
                                                    onClick={() => updateSettingOrderFBO(value.field, !settingOrder[value.field])}
                                                />
                                            </GridColumn>
                                        </>
                                        : <GridColumn floated='left' width={13}><h3>{value.name}</h3></GridColumn>}

                                </GridRow>

                            </React.Fragment>
                        ))
                    }

                </Grid>

            </Segment></Sidebar>
            <Accordion title={'Синхронизация заказов FBS'} saveName={'AccordionOrdersSyncFBS'}
                       inverted={inverted}
                       headerContent={
                           <>
                               <Checkbox className={inverted ? 'dark' : ''} toggle
                                         checked={Boolean(settingOrder.sync)}
                                         onClick={() => updateSettingOrderFBO('sync', !settingOrder.sync)}
                               ></Checkbox>
                               <label style={{
                                   color: inverted ? 'white' : '#706f6f',
                                   width: "100%",
                                   marginLeft: "30px"
                               }}>Загрузка каждые 5 минут</label>
                               <div className='buttons-connect'>
                                   <Button inverted={inverted} basic
                                           onClick={() => setAdvancedSettingOpen(true)

                                           }
                                   >Расширенные настройки</Button>
                                   <Button primary inverted={inverted}
                                           onClick={onSaveSettingOrder}
                                   >Сохранить</Button>

                               </div>
                           </>
                       }>
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>

                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Организация</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('organization')}
                                        list={listSelect}
                                        onSelect={(item => {
                                            updateSettingOrderSelect('organization', item);
                                            updateSettingOrderSelect('organization_account', {id: "", name: ""});
                                            updateSettingOrderSelect('contract', {id: "", name: ""});
                                        })}
                                        onOpen={() => handleOpenSelect('organization')}
                                ></Select>
                            </GridColumn>
                        </GridRow>

                        {Boolean(settingOrder.organization_account_show) && <GridRow>
                            <GridColumn width={2}>
                                <strong>Счет организации</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('organization_account')}
                                        onSelect={(item => updateSettingOrderSelect('organization_account', item))}
                                        onOpen={() => handleOrganizationAccount()}
                                        list={listSelect}
                                ></Select>
                            </GridColumn>
                        </GridRow>}


                        <Divider></Divider>

                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Контрагент</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('counterparty')}
                                        list={listSelect}
                                        placeholder='Начните печатать'
                                        onSelect={(item => updateSettingOrderSelect('counterparty', item))}
                                        onOpen={(text) => handleTextChange(text)}
                                        onTextEnter={(text) => handleTextChange(text)}
                                ></Select>
                            </GridColumn>
                        </GridRow>


                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Договор комиссии</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('contract')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('contract', item))}
                                        onOpen={getContract}

                                ></Select>
                            </GridColumn>
                        </GridRow>

                        {Boolean(settingOrder.order_numbering_type_show) && <><Divider></Divider>
                            <GridRow>
                                <GridColumn width={2}>
                                    <strong>Тип нумерации заказов</strong>
                                </GridColumn>
                                <GridColumn width={3}>
                                    {/*<div style={{display: 'flex', alignItems: 'center'}}>*/}
                                    <Select
                                        defaultValue={getSelectDefaultValue('order_numbering_type')}
                                        list={[{id: 'moysklad', name: 'Нумерация МойСклад'},
                                            {id: 'wildberries', name: 'Нумерация Wildberries'}]}
                                        onSelect={(item => updateSettingOrderSelect('order_numbering_type', item))}
                                        emptyItem={false}
                                    ></Select>
                                    {/*{settingOrder.order_numbering_type_id === 'moysklad' && <Input value={settingOrder.order_numbering_type_prefix}*/}
                                    {/*   onChange={event => setSettingOrder(value => ({...value, order_numbering_type_prefix: event.target.value}))}*/}
                                    {/*   style={{width:'100px', marginLeft: '10px'}}></Input>}*/}
                                    {/*</div>*/}

                                </GridColumn>
                            </GridRow></>}

                        {syncOrderFieldData && syncOrderFieldData.map((value) => (
                            (value.show === null || Boolean(settingOrder[value.show])) &&
                            (<React.Fragment key={value.selectKey || value.checked}>
                                {value.divider && <Divider></Divider>}
                                <GridRow>
                                    <GridColumn width={2}>
                                        <strong>{value.name}</strong>
                                    </GridColumn>
                                    {value.selectKey &&
                                        <GridColumn width={2}>
                                            <Select defaultValue={getSelectDefaultValue(value.selectKey)}
                                                    list={listSelect}
                                                    onSelect={(item => updateSettingOrderSelect(value.selectKey ?? "", item))}
                                                    onOpen={() => handleOpenSelect(value.url ?? "")}
                                            ></Select>
                                        </GridColumn>}
                                    {value.checked &&
                                        <GridColumn width={2}>
                                            <Checkbox slider className={inverted ? 'dark' : ''}
                                                      checked={Boolean(settingOrder[value.checked])}
                                                      onClick={() => value.checked && updateSettingOrderFBO(value.checked, !settingOrder[value.checked])}
                                            ></Checkbox>
                                        </GridColumn>
                                    }
                                </GridRow>
                            </React.Fragment>)
                        ))}


                        {syncOrderAditionalField && syncOrderAditionalField.map((item) => (
                            (item.show === null || Boolean(settingOrder[item.show])) &&
                            (<React.Fragment key={item.selectKey}>
                                <Divider></Divider>
                                <GridRow>
                                    <GridColumn width={2}>
                                        <strong>{item.name}</strong>
                                        <p className='subtext'>{item.subText}</p>
                                    </GridColumn>
                                    {item.selectKey &&
                                        <GridColumn width={2}>
                                            <Select defaultValue={getSelectDefaultValue(item.selectKey)}
                                                    list={listSelect}

                                                    onSelect={(value => updateSettingOrderSelect(item.selectKey, value))}
                                                    onOpen={() => handleOpenSelectCustomerOrderAttributes(item.sort)}
                                            ></Select>

                                        </GridColumn>}
                                </GridRow>
                            </React.Fragment>)
                        ))}


                    </Grid>
                </Segment>
            </Accordion>

            <Accordion title='Статусы' saveName={'AccordionOrdersSyncStatusFBS'}
                       inverted={inverted}
            >
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>
                        <GridRow>
                            <GridColumn width={2}>
                                <p className='subtext'>Статусы Wildberries</p>
                                <strong>Новые</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <p className='subtext'>Статусы МойСклад</p>
                                <Select
                                    defaultValue={getSelectDefaultValue('new_state')}
                                    list={listSelect}
                                    onSelect={(item => updateSettingOrderSelect('new_state', item))}
                                    onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>На сборке</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select
                                    defaultValue={getSelectDefaultValue('assembly')}
                                    list={listSelect}
                                    onSelect={(item => updateSettingOrderSelect('assembly', item))}
                                    onOpen={() => handleOpenSelect('states')}
                                ></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>В доставке</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('in_delivery')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('in_delivery', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Архив - Отсортировано</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('archive_sorted')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('archive_sorted', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Архив - Прибыло на ПВЗ</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('archive_arrived_pickup')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('archive_arrived_pickup', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Архив - Доставлено</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('archive_delivered')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('archive_delivered', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Архив - Отменено</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('archive_canceled')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('archive_canceled', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Архив - Отменено продавцом</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('archive_canceled_seller')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('archive_canceled_seller', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Архив - Брак</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('archive_defect')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('archive_defect', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Архив - Отменено покупателем в первый час</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('declined_by_client')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('declined_by_client', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                    </Grid>

                </Segment>
            </Accordion>

            <Accordion title="Документы" saveName={'AccordionOrdersDocsFBS'}
                       inverted={inverted}>
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>
                        {/*<GridRow>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <strong>Создавать входящий платеж</strong>*/}
                        {/*        <p className='subtext'>Выберите на каком этапе автоматически создавать "Документ -*/}
                        {/*            Входящий платеж".</p>*/}
                        {/*    </GridColumn>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <Select defaultValue={getSelectDefaultValue('create_incoming_payment')}*/}
                        {/*                list={listSelect}*/}
                        {/*                onSelect={(item => updateSettingOrderSelect('create_incoming_payment', item))}*/}
                        {/*                onOpen={() => handleOpenSelect('states')}></Select>*/}
                        {/*    </GridColumn>*/}
                        {/*</GridRow>*/}
                        {/*<GridRow>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <strong>Не удалять входящий платеж при отмене заказа</strong>*/}
                        {/*    </GridColumn>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <Checkbox slider className={inverted ? 'dark' : ''}*/}
                        {/*                  checked={Boolean(settingOrder.not_delete_incoming_payment)}*/}
                        {/*                  onClick={() => updateSettingOrderFBO('not_delete_incoming_payment', !settingOrder.not_delete_incoming_payment)}/>*/}
                        {/*    </GridColumn>*/}
                        {/*</GridRow>*/}
                        {/*<Divider></Divider>*/}
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Создавать отгрузки на основании поставок WB</strong>
                                <p className='subtext'>Функция создает документ отгрузки в МойСклад, добавляя в него
                                    товары из новых сборочных заданий, аналогично поставке в Wildberries.</p>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Checkbox slider className={inverted ? 'dark' : ''}
                                          checked={Boolean(settingOrder.create_shipment)}
                                          onClick={() => updateSettingOrderFBO('create_shipment', !settingOrder.create_shipment)}/>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Перенести поставки FBS из Wildberries в МойСклад как отгрузки </strong>
                                <p className='subtext'>Можно перенести поставки за последние 30 дней</p>
                            </GridColumn>
                            <GridColumn width={2}>
                                <p className='subtext'>Начало периода</p>
                                <DatePicker locale="ru"
                                            calendarIcon={null}
                                            value={settingOrder.manual_shipment_date}
                                            className='date-time-picker-300'
                                            onChange={(e) => {
                                                const selectedDate = e instanceof Date ? e : new Date();
                                                setSettingOrder(prev => ({
                                                    ...prev,
                                                    manual_shipment_date: formatDate(selectedDate),
                                                }))
                                            }}
                                />
                            </GridColumn>
                            <GridColumn width={2}>
                                <button
                                    disabled={Boolean(settingOrder.GetShipments) || !connected}
                                    onClick={() => setOpenConfirmGetShipments(true)}
                                    style={{paddingLeft: 0}}
                                    className={inverted ? 'button-link inverted' : 'button-link'}>Загрузить
                                </button>
                                {Boolean(settingOrder.GetShipments) &&
                                    <Icon loading name='spinner' inverted={inverted}/>
                                }
                                <Confirm
                                    className='modal-dialog'
                                    open={openConfirmGetShipments}
                                    content='Запустить перенос поставок из WIldberries в МойСклад?'
                                    cancelButton='Нет'
                                    confirmButton='Да'
                                    onCancel={() => setOpenConfirmGetShipments(false)}
                                    onConfirm={() => {
                                        setOpenConfirmGetShipments(false);
                                        handleRunProcess('GetShipments')
                                    }}
                                />
                            </GridColumn>
                        </GridRow>

                        {/*<GridRow>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <strong>Не удалять отгрузку при отмене заказа</strong>*/}
                        {/*    </GridColumn>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <Checkbox slider className={inverted ? 'dark' : ''}*/}
                        {/*                  checked={Boolean(settingOrder.not_delete_shipment)}*/}
                        {/*                  onClick={() => updateSettingOrderFBO('not_delete_shipment', !settingOrder.not_delete_shipment)}/>*/}
                        {/*    </GridColumn>*/}
                        {/*</GridRow>*/}
                        {/*<Divider></Divider>*/}
                        {/*<GridRow>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <strong>Создавать возврат</strong>*/}
                        {/*        <p className='subtext'>Выберите склад на который создавать возврат если заказ будет*/}
                        {/*            отменен или возвращен.</p>*/}
                        {/*    </GridColumn>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <Select defaultValue={getSelectDefaultValue('create_return')}*/}
                        {/*                list={listSelect}*/}
                        {/*                onSelect={(item => updateSettingOrderSelect('create_return', item))}*/}
                        {/*                onOpen={() => handleOpenSelect('states')}></Select>*/}
                        {/*    </GridColumn>*/}
                        {/*</GridRow>*/}
                        {/*<GridRow>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <strong>Не создавать исх. платеж при возврате</strong>*/}
                        {/*    </GridColumn>*/}
                        {/*    <GridColumn width={2}>*/}
                        {/*        <Checkbox slider className={inverted ? 'dark' : ''}*/}
                        {/*                  checked={Boolean(settingOrder.not_create_out_payment)}*/}
                        {/*                  onClick={() => updateSettingOrderFBO('not_create_out_payment', !settingOrder.not_create_out_payment)}/>*/}
                        {/*    </GridColumn>*/}
                        {/*</GridRow>*/}
                    </Grid>
                </Segment>
            </Accordion>


            <Accordion title='Обязательные дополнительные поля' saveName={'AccordionOrdersRequiredFBS'}
                       inverted={inverted}>
                <Segment inverted={inverted}>
                    <OrdersRequiredAttributesForm settingOrder={settingOrder}
                                                  setSettingOrder={setSettingOrder}
                    ></OrdersRequiredAttributesForm>
                </Segment>
            </Accordion>
            <div style={{height: "200px"}}>
            </div>
        </>
    )
}