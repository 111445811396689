import React, {useEffect, useRef, ReactNode} from 'react';
import './Sidebar.scss';


type SideBarProps = {
    isOpen: boolean;
    position: 'left' | 'right'
    onClose?: () => void;
    autoClose?: boolean;
    children: ReactNode;
}


export const Sidebar = ({ isOpen, position, onClose = () => {}, autoClose = true, children }: SideBarProps) => {
    const sidebarRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (!autoClose) return ;
        const handleClickOutside = (event: MouseEvent) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
                onClose(); // вызываем функцию, переданную из родителя
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, [sidebarRef]);

    const className = (position === 'left') ? `sidebar left ${isOpen ? 'open' : ''}` : `sidebar right ${isOpen ? 'open' : ''}`;

    return (
        <div ref={sidebarRef} className={className}>
            {children}
        </div>
    );
};
