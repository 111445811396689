import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import Accordion from "./Accordion/Accordion";
import {useGlobalContext} from "./GlobalProvider";
import PhoneNumberInput from "./PhoneNumberInput/PhoneNumberInput";
import logo from '../img/logo.svg'

import {
    ListSettings,
    ApiListSettings,
    ApiSetting,
    SettingOrderFBS,
    SettingOrderFBO,
    ApiResponse,
    ApiAttributesRequired, RequiredAttribute, SettingConnect, SettingProduct, SettingCommissionerReport, ApiContextKey
} from '../types';

import {
    disconnectSettingConnect, getAccountId, getCustomerorderAttributesRequired,
    getListSettings, getSettingCommissionerReport,
    getSettingConnect,
    getSettingOrder, getSettingProducts,
    saveSettingConnect
} from '../requests'

import {formatDate, showError, showSuccess} from '../tools'


import {
    Image,
    Icon,
    Menu,
    Sidebar,
    Segment,
    Header,
    Button,
    Grid,
    GridRow,
    GridColumn,
    Divider,
    Input,
    Checkbox
} from "semantic-ui-react";
import {Products} from "./Products/Products";
import {OrdersFBS} from "./Orders/OrdersFBS";
import {OrdersFBO} from "./Orders/OrdersFBO";
import {CommissionerReport} from "./Orders/CommissionerReport";
import {LogErrors} from "./LogErrors";
import ContactPage from "./ContactPage";
import {LogUser} from "./LogUser";
import {Select, SelectItem} from "./Select/Select";


const initialSettingConnect: SettingConnect = {
    connected: false,
    agreement: false,
    name_store: '',
    token_wildberries: null,
    time_zone: 'Asia/Yekaterinburg',
    exp: null,
    tel: '',
    telegram_limit_account: 3,
};

const timeZones: SelectItem[] = [
    { id: 'Europe/Kaliningrad', name: 'UTC+2' },
    { id: 'Europe/Moscow', name: 'UTC+3' },
    { id: 'Europe/Samara', name: 'UTC+4' },
    { id: 'Asia/Yekaterinburg', name: 'UTC+5' },
    { id: 'Asia/Kolkata', name: 'UTC+5:30' },
    { id: 'Asia/Dhaka', name: 'UTC+6' },
    { id: 'Asia/Bangkok', name: 'UTC+7' },
    { id: 'Asia/Shanghai', name: 'UTC+8' },
    { id: 'Asia/Tokyo', name: 'UTC+9' },
    { id: 'Australia/Sydney', name: 'UTC+10' },
    { id: 'Pacific/Noumea', name: 'UTC+11' },
    { id: 'Pacific/Fiji', name: 'UTC+12' }
];


const simpleTypes = ['string', 'text', 'link', 'long', 'time', 'double'];


const initialSettingOrderFBS: SettingOrderFBS = {
    sync: false,

    archive_arrived_pickup_id: "",
    archive_arrived_pickup_name: "",
    archive_canceled_id: "",
    archive_canceled_name: "",
    archive_delivered_id: "",
    archive_delivered_name: "",
    archive_sorted_id: "",
    archive_sorted_name: "",
    assembly_id: "",
    assembly_name: "",
    counterparty_id: "",
    counterparty_name: "",
    contract_id: '',
    contract_name: '',
    contract_show: false,


    type_delivery_id: '',
    type_delivery_name: '',
    type_delivery_show: false,

    type_product_id: '',
    type_product_name: '',
    type_product_show: false,

    commission_show: false,

    commission_wildberries_id: '',
    commission_wildberries_name: '',

    commission_logistics_id: '',
    commission_logistics_name: '',

    commission_fine_id: '',
    commission_fine_name: '',

    declined_by_client_id: '',
    declined_by_client_name: '',

    create_shipment_id: '',
    create_shipment_name: '',

    order_numbering_type_id: '',
    order_numbering_type_name: '',
    order_numbering_type_prefix: '',
    order_numbering_type_show: false,
    archive_canceled_seller_id: '',
    archive_canceled_seller_name: '',
    archive_defect_id: '',
    archive_defect_name: '',
    create_incoming_payment_id: "",
    create_incoming_payment_name: "",
    create_return_id: "",
    create_return_name: "",
    currency_id: "",
    currency_name: "",
    currency_show: false,
    employee_id: "",
    employee_name: "",
    employee_show: false,
    group_id: "",
    group_name: "",
    group_show: false,
    in_delivery_id: "",
    in_delivery_name: "",
    new_state_id: "",
    new_state_name: "",
    not_create_out_payment: false,
    not_delete_incoming_payment: false,
    not_delete_shipment: false,
    organization_account_id: "",
    organization_account_name: "",
    organization_account_show: false,
    organization_id: "",
    organization_name: "",
    project_id: "",
    project_name: "",
    project_show: false,
    reservation: false,
    reservation_show: false,
    sales_channel_id: "",
    sales_channel_name: "",
    sales_channel_show: false,
    required_additional_fields: null,
    create_shipment: false,
    manual_shipment_date: formatDate(new Date()),
    GetShipments: false,
}

const initialSettingCommissionerReport: SettingCommissionerReport = {
    sync: false,

    counterparty_id: "",
    counterparty_name: "",
    currency_id: "",
    currency_name: "",
    currency_show: false,

    commission_show: false,

    contract_id: '',
    contract_name: '',
    contract_show: false,


    reservation: false,
    reservation_show: false,
    employee_id: "",
    employee_name: "",
    employee_show: false,
    group_id: "",
    group_name: "",
    group_show: false,

    organization_account_id: "",
    organization_account_name: "",
    organization_account_show: false,
    organization_id: "",
    organization_name: "",
    project_id: "",
    project_name: "",
    project_show: false,
    sales_channel_id: "",
    sales_channel_name: "",
    sales_channel_show: false,
    required_additional_fields: null,


    type_delivery_id: "",
    type_delivery_name: "",
    type_delivery_show: false,

    commission_wildberries_id: "",
    commission_wildberries_name: "",
    commission_wildberries_show: false,

    commission_logistics_id: "",
    commission_logistics_name: "",
    commission_logistics_show: false,


    commission_fine_id: "",
    commission_fine_name: "",
    commission_fine_show: false,


    additional_payment_id: "",
    additional_payment_name: "",
    additional_payment_show: false,

    warehouse_price_id: "",
    warehouse_price_name: "",
    warehouse_price_show: false,

    deduction_id: "",
    deduction_name: "",
    deduction_show: false,

    acceptance_id: "",
    acceptance_name: "",
    acceptance_show: false,

    not_create_incoming_payment: true,

    load_commissioner_report: false,

    start_period: "2023-12-12 00:00:00",
    end_period: "2023-12-12 00:00:00",

}

const initialSettingOrderFBO: SettingOrderFBO = {
    sync: false,

    counterparty_id: "",
    counterparty_name: "",
    currency_id: "",
    currency_name: "",
    currency_show: false,


    data_delivery_id: '',
    data_delivery_name: '',
    data_delivery_show: false,

    data_return_id: '',
    data_return_name: '',
    data_return_show: false,


    commission_show: false,

    commission_wildberries_id: '',
    commission_wildberries_name: '',

    commission_logistics_id: '',
    commission_logistics_name: '',

    commission_fine_id: '',
    commission_fine_name: '',


    contract_id: '',
    contract_name: '',
    contract_show: false,

    order_numbering_type_id: '',
    order_numbering_type_name: '',
    order_numbering_type_prefix: '',
    order_numbering_type_show: false,

    reservation: false,
    reservation_show: false,
    employee_id: "",
    employee_name: "",
    employee_show: false,
    group_id: "",
    group_name: "",
    group_show: false,

    organization_account_id: "",
    organization_account_name: "",
    organization_account_show: false,
    organization_id: "",
    organization_name: "",
    project_id: "",
    project_name: "",
    project_show: false,
    sales_channel_id: "",
    sales_channel_name: "",
    sales_channel_show: false,
    required_additional_fields: null,

    new_state_id: "",
    new_state_name: "",
    delivered_id: "",
    delivered_name: "",
    canceled_id: "",
    canceled_name: "",
    arrived_id: "",
    arrived_name: "",

    create_shipment_incoming_payment: false,

    not_delete_shipment_incoming_payment: false,

    create_return_out_payment: false,

    not_delete_return_out_payment: false,


}


const initialProducts: SettingProduct = {
    variant_wildberries_to_mod_moysklad: false,
    not_create_new_product_if_not_exist_bind: false,
    compare_characteristics_modifications_id: '',
    compare_characteristics_modifications_name: '',
    brend_id: '',
    brend_name: '',
    brend_show: false,
    size_id: '',
    size_name: '',
    size_show: false,
    color_id: '',
    color_name: '',
    color_show: false,
    unload_fbs_remains: false,
    storage_fbs: null, // или соответствующий объект JSON, если структура известна
    storage_fbo_id: '',
    storage_fbo_name: '',
    storage_return_id: '',
    storage_return_name: '',
    accounting_minimum_balance_id: '',
    accounting_minimum_balance_name: '',
    accounting_minimum_balance_show: false,
    transfer_balances_id: '',
    transfer_balances_name: '',
    transfer_price_id: '',
    transfer_price_name: '',
    upload_price_moysklad_to_wildberries: false,
    retail_price_id: '',
    retail_price_name: '',
    price_before_discounts_id: '',
    price_before_discounts_name: '',
    last_sync: null,
    last_balances_send: null,
    last_prices_send: null,
    product_wildberries_to_moysklad: false,
    price_moysklad_to_wildberries: false,
    price_wildberries_to_moysklad: false,
    sync_product: false,
    FBO_stock_wildberries_to_moySklad: false,
    FBS_stock_moysklad_to_wildberries: false
};


const getDefaultValue = (item: RequiredAttribute): RequiredAttribute => {
    switch (item.type) {
        case 'string':
        case 'text':
            if (!item.name) item.name = 'Строка';
            break;

        case 'link':
            if (!item.name) item.name = 'https://localhost';
            break;

        case 'long':
            if (!item.name) item.name = "100";
            break;

        case 'double':
            if (!item.name) item.name = "100.1";
            break;

        case 'time':
            if (!item.name) item.name = '2023-12-06 00:00:00.000';
            break;

    }

    return item
}


type FBO_FBS_COMM = 'FBO' | 'FBS' | 'COMM';


export const Main: React.FC = () => {
    // eslint-disable-next-line
    const [contextKey, setContextKey] = useState<string>('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [activeItem, setActiveItem] = useState('home');
    const [activeItemHomeMenu, setActiveItemHomeMenu] = useState('HomeSettings');
    const [activeItemOrderMenu, setActiveItemOrderMenu] = useState<FBO_FBS_COMM>('FBS');
    const [openAccountsMenu, setOpenAccountsMenu] = useState(false);
    const [listSettings, setlistSettings] = useState<ListSettings[]>([]);
    const [settingConnect, setSettingConnect] = useState<SettingConnect>(initialSettingConnect);
    const [orderSettingFBS, setSettingOrderFBS] = useState<SettingOrderFBS>(initialSettingOrderFBS);
    const [orderSettingFBO, setSettingOrderFBO] = useState<SettingOrderFBO>(initialSettingOrderFBO);
    const [settingCommissionerReport, setSettingCommissionerReport] = useState<SettingCommissionerReport>(initialSettingCommissionerReport);
    const [settingProduct, setSettingProduct] = useState<SettingProduct>(initialProducts);
    const [showLogError, setShowLogError] = useState(0);


    const {
        inverted,
        toggleInverted,
        activeSettingsId,
        setActiveSettingsId,
        account_id,
        setAccount_id,
        setConnected
    } = useGlobalContext();


    const handleItemClick = (name: string) => setActiveItem(name);
    const handleItemClickItemHomeMenu = (name: string) => {
        if (name === 'HomeLogUser')
            setShowLogError(prevState => prevState + 1);
        else if (showLogError < 20)
            setShowLogError(0);
        setActiveItemHomeMenu(name);
    }
    const handleItemClickItemOrderMenu = (name: FBO_FBS_COMM) => setActiveItemOrderMenu(name);

    const updateSettingConnect = (key: string, value: any) => {
        setSettingConnect(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const updateNameStore = (value: string | null) => {
        if (!activeSettingsId || !value) return;

        setlistSettings((prevSettings) =>
            prevSettings.map((setting) =>
                setting.id === activeSettingsId ? {...setting, name_store: value} : setting
            )
        );
    };

    const loadSettingConnect = () => {
        getSettingConnect(account_id, activeSettingsId)
            .then(value => {
                const response: ApiSetting = value.data;
                if (response.data && response.success) {

                    setSettingConnect(response.data);

                } else
                    showError('Ошибка ответа получения главных настрек', false);
            })
            .catch(error => {
                showError('Ошибка запроса получения главных настрек', false);
            });
    }

    const loadRealAttributesRequired = (settingOrder: SettingOrderFBS | SettingOrderFBO) => {
        getCustomerorderAttributesRequired(account_id)
            .then(value => {
                const response: ApiAttributesRequired = value.data;
                if (response.data && response.success) {

                    const mergedAttributes: RequiredAttribute[] = response.data.map(apiAttr => {
                        const existingAttr = settingOrder.required_additional_fields ? settingOrder.required_additional_fields.find(attr => attr.id === apiAttr.id) : null;
                        return existingAttr ? {
                            ...existingAttr,
                            ...(!(apiAttr.type in simpleTypes) && {customentityValue: existingAttr.customentityValue})
                        } : {
                            id: apiAttr.id,
                            name: "",
                            type: apiAttr.type,
                            nameField: apiAttr.nameField,
                            ...(!(apiAttr.type in simpleTypes) && {customentityValue: apiAttr.defaultValue})
                        };
                    });

                    mergedAttributes.map(item => {
                        return getDefaultValue(item);
                    })


                    if ("archive_sorted_id" in settingOrder) {
                        setSettingOrderFBS(prev => ({
                            ...prev,
                            required_additional_fields: mergedAttributes
                        }))
                    } else {
                        setSettingOrderFBO(prev => ({
                            ...prev,
                            required_additional_fields: mergedAttributes
                        }))
                    }
                } else {
                    console.error(response.error);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }


    const loadSettingOrder = <T, >() => {
        getSettingOrder(account_id, activeSettingsId, activeItemOrderMenu)
            .then(value => {
                const response: ApiResponse<T> = value.data;
                if (response.data && response.success) {

                    if (activeItemOrderMenu === 'FBS') {
                        const data = response.data as unknown as SettingOrderFBS;

                        setSettingOrderFBS(data);
                        loadRealAttributesRequired(data);

                    }

                    if (activeItemOrderMenu === 'FBO') {
                        const data = response.data as unknown as SettingOrderFBO;
                        setSettingOrderFBO(data);
                        loadRealAttributesRequired(data);

                    }

                } else
                    showError('Ошибка ответа получения настрек заказа', false);
            })
            .catch(error => {
                showError('Ошибка запроса получения настрек заказа', false);
            });
    }


    const loadSettingProducts = () => {
        getSettingProducts(account_id, activeSettingsId)
            .then(value => {
                const response: ApiResponse<SettingProduct> = value.data;
                if (response.data && response.success) {

                    setSettingProduct(response.data);
                } else
                    showError('Ошибка ответа получения настроек товара', false);
            })
            .catch(error => {
                showError('Ошибка запроса получения настроек товара', false);
            });
    }


    const onSaveSettingConnect = () => {

        saveSettingConnect(account_id, activeSettingsId, settingConnect)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    showSuccess("Подключено", inverted);
                    updateSettingConnect('connected', true);
                    updateNameStore(settingConnect.name_store);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    const onDisconnectSettingConnect = () => {

        disconnectSettingConnect(account_id, activeSettingsId)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    updateSettingConnect('connected', false);

                    showSuccess("Отключено", inverted);
                } else {
                    showError('Ошибка ответа отключение настроик подключения', inverted, false);
                }
            })
            .catch(error => {
                showError('Ошибка запроса отключение настроик подключения', inverted, false);
            });
    }


    // const [list, setList] = useState<SelectItem[]>([])
    // const [defaultValue, setDefaultValue] = useState<string[] | string>("");
    // const [defaultValue2, setDefaultValue2] = useState<string[] | string>("");
    // const [requestSend, setRequestSend] = useState(false);

    useEffect(() => {
        setConnected(settingConnect.connected);
        // eslint-disable-next-line
    }, [settingConnect.connected]);

    useEffect(() => {
        const _contextKey = searchParams.get('contextKey');
        if (process.env.REACT_APP_TOKEN) {
            setAccount_id(process.env.REACT_APP_TOKEN);
        } else if (_contextKey) {
            setContextKey(_contextKey);
            getAccountId(_contextKey)
                .then(value => {
                    const response: ApiContextKey = value.data;
                    if (response.data && response.success) {
                        setAccount_id(response.data.accountId);
                    } else {
                        showError('Ошибка ответа: не верный contextKey', inverted, false);
                    }
                })
                .catch(error => {
                    showError('Ошибка запроса getContextKey', inverted, false);
                });
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Изменение цвета фона body
        document.body.style.backgroundColor = inverted ? 'black' : '#F0F0F0';
    }, [inverted]);


    useEffect(() => {
        if (!account_id) return;
        getListSettings(account_id)
            .then(value => {
                const response: ApiListSettings = value.data;
                if (response.data && response.success) {
                    if (!activeSettingsId) {
                        const firstSettingKey = response.data[0];
                        if (firstSettingKey) {
                            setActiveSettingsId(firstSettingKey['id']);
                        }
                    }
                    setlistSettings(response.data);
                } else {
                    showError('Ошибка ответа получения списка аккаунтов', inverted, false);
                }
            })
            .catch(error => {
                showError('Ошибка запроса получения списка аккаунтов', inverted, false);
            });
        // eslint-disable-next-line
    }, [account_id])

    useEffect(() => {
        if (!account_id && !activeSettingsId) return;


        if (activeItem === 'home' && activeItemHomeMenu === 'HomeSettings') {
            loadSettingConnect();
        }

        if (activeItem === 'orders' && activeItemOrderMenu === 'FBS') {
            loadSettingOrder<SettingOrderFBS>();
        }

        if (activeItem === 'orders' && activeItemOrderMenu === 'FBO') {
            loadSettingOrder<SettingOrderFBO>();
        }

        if (activeItem === 'orders' && activeItemOrderMenu === 'COMM') {
            getSettingCommissionerReport(account_id, activeSettingsId)
                .then(value => {
                    const response: ApiResponse<SettingCommissionerReport> = value.data;
                    if (response.data && response.success) {
                        setSettingCommissionerReport(response.data);

                    } else
                        showError('Ошибка ответа получения настрек заказа', false);
                })
                .catch(error => {
                    showError('Ошибка запроса получения настрек заказа', false);
                });
        }

        if (activeItem === 'product') {
            loadSettingProducts();
        }

        if (showLogError < 20 && activeItemHomeMenu !== 'HomeLogUser')
            setShowLogError(0);
// eslint-disable-next-line
    }, [activeSettingsId, activeItem, activeItemOrderMenu, activeItemHomeMenu])


    const disable = (!settingConnect.name_store ||
        !settingConnect.token_wildberries ||
        !settingConnect.agreement ||
        settingConnect.tel.length < 11)


    const exp = () => {
        if (!settingConnect.exp) {
            return <p className='exp-token' style={{ color: 'grey' }}>Токен не содержит времени истечения</p>;
        }
        const dateStr = settingConnect.exp.replace(/\./g, '-').replace(' ', 'T');
        const dateExp = new Date(dateStr);
        const now = new Date();

        if (dateExp > now) {
            return (<p className='exp-token' style={{ color: 'green' }}>Токен активен до {settingConnect.exp} UTC</p>);
        } else {
            return <p className='exp-token' style={{ color: 'red' }}>Срок действия токена истек</p>;
        }
    };

    const getTimeZoneName = (id: string): SelectItem => {
        const timeZone = timeZones.find(item => item.id === id);
        return timeZone || { id: 'Asia/Yekaterinburg', name: 'UTC+5' };
    };

    return (
        <>

            <Sidebar.Pushable
                style={{minHeight: '100vh'}}>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted={inverted}
                    vertical
                    onHide={() => setOpenAccountsMenu(false)}
                    visible={openAccountsMenu}

                    style={{minHeight: '100vh'}}
                >
                    <Menu.Item>
                        <Header inverted={inverted} as='h3'>Аккаунты</Header>
                    </Menu.Item>
                    {Array.isArray(listSettings) && listSettings.map((account, index) => {
                        const today = new Date();
                        const endDate = new Date(account.data_end ?? '');
                        today.setHours(0, 0, 0, 0);
                        endDate.setHours(0, 0, 0, 0);

                        const name = account.name_store ? account.name_store : 'Аккаунт ' + (index + 1);
                        const active = activeSettingsId === account.id ? 'active' : '';
                        const subscriptionEnded = account.data_end ? today > endDate : false;
                        const redClass = subscriptionEnded ? 'red' : '';
                        const subtext = account.main ? '' : subscriptionEnded ? 'Подписка закончилась ' + account.data_end : 'До ' + account.data_end;

                        return (
                            <Menu.Item
                                key={account.id}
                                className={'itemAccountsMenu ' + active}
                                as={'a'}
                                onClick={() => setActiveSettingsId(account.id)}
                            >
                                <p className='account'>{name}</p>
                                <p className={'subtext ' + redClass}>{subtext}</p>
                            </Menu.Item>
                        );
                    })}

                </Sidebar>
                <Sidebar.Pusher dimmed={openAccountsMenu}>

                    <Sidebar.Pushable
                        style={{minHeight: '100vh',}}>
                        <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'
                            inverted
                            vertical
                            visible={true}
                            width='very thin'
                            style={{minHeight: '100vh'}}
                            className='mainSideBar'
                        >
                            <Menu.Item
                                as={'a'}
                                name='accounts'
                                onClick={() => {
                                    if (Object.entries(listSettings).length > 1)
                                        setOpenAccountsMenu(true)
                                }}

                            >
                                <div>
                                    <Image src={logo} style={{maxWidth: '30px'}}/>
                                </div>
                            </Menu.Item>
                            <Menu.Item
                                as={'a'}
                                name='home'
                                active={activeItem === 'home'}
                                onClick={() => handleItemClick('home')}
                            >
                                <div className='main-menu-item-icon-text'>
                                    <Icon name='home' size='large'/>
                                    <label style={{fontSize: '0.7em'}}>Главная</label>
                                </div>
                            </Menu.Item>

                            <Menu.Item
                                as={'a'}
                                name='product'
                                active={activeItem === 'product'}
                                onClick={() => handleItemClick('product')}
                            >
                                <div className='main-menu-item-icon-text'>
                                    <Icon name='shopping cart' size='large'/>
                                    <label style={{fontSize: '0.7em'}}>Товары</label>
                                </div>
                            </Menu.Item>


                            <Menu.Item
                                as={'a'}
                                name='orders'
                                active={activeItem === 'orders'}
                                onClick={() => handleItemClick('orders')}
                            >
                                <div className='main-menu-item-icon-text'>
                                    <Icon style={{marginLeft: '3px'}} name='sync' size='large'/>
                                    <label style={{fontSize: '0.7em'}}>Заказы</label>
                                </div>
                            </Menu.Item>


                        </Sidebar>
                        <Sidebar.Pusher style={{marginLeft: '60px'}}>


                            {/* Меню Home */}
                            {activeItem === 'home' &&
                                <Sidebar.Pushable
                                    style={{minHeight: '100vh'}}>
                                    <Sidebar
                                        as={Menu}
                                        animation='overlay'
                                        inverted={inverted}
                                        vertical
                                        visible={true}
                                        className='homeSideBar'
                                    >
                                        <Menu.Item as={'div'}>
                                            <Header inverted={inverted} as='h3'>Главная</Header>
                                        </Menu.Item>

                                        <Menu.Item
                                            as={'a'}
                                            name='HomeSettings'
                                            active={activeItemHomeMenu === 'HomeSettings'}
                                            onClick={() => handleItemClickItemHomeMenu('HomeSettings')}
                                        >
                                            Подключения
                                        </Menu.Item>


                                        <Menu.Item style={{height: '65px', display: 'flex', alignItems: 'center'}}
                                                   as={'a'}
                                                   name='HomeLogUser'
                                                   active={activeItemHomeMenu === 'HomeLogUser'}
                                                   onClick={() => handleItemClickItemHomeMenu('HomeLogUser')}
                                        >
                                            Журнал
                                        </Menu.Item>

                                        {showLogError >= 20 &&
                                            <Menu.Item style={{height: '65px', display: 'flex', alignItems: 'center'}}
                                                       as={'a'}
                                                       name='HomeErrorLogs'
                                                       active={activeItemHomeMenu === 'HomeErrorLogs'}
                                                       onClick={() => handleItemClickItemHomeMenu('HomeErrorLogs')}
                                            >
                                                Журнал ошибок
                                            </Menu.Item>
                                        }
                                        <Menu.Item
                                            as={'a'}
                                            name='HomeContacts'
                                            active={activeItemHomeMenu === 'HomeContacts'}
                                            onClick={() => handleItemClickItemHomeMenu('HomeContacts')}
                                        >
                                            Помощь

                                        </Menu.Item>
                                    </Sidebar>
                                    <Sidebar.Pusher
                                        style={{overflow: 'visible'}}
                                    >
                                        <div className='sidebar-pusher-div'>
                                            {activeItemHomeMenu === 'HomeSettings' &&
                                                <Accordion
                                                    title={"Подключение к кабинету"}
                                                    accordion={false}
                                                    inverted={inverted}
                                                    headerContent={
                                                        <>
                                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                                <Checkbox className={inverted ? 'dark' : ''} toggle
                                                                          onClick={toggleInverted}
                                                                          checked={inverted}></Checkbox>
                                                                <label style={{
                                                                    width: '100px',
                                                                    color: inverted ? 'white' : '#706f6f'
                                                                }}>Темная тема</label>
                                                            </div>

                                                            <div className='buttons-connect'>


                                                                <Button inverted={inverted}
                                                                        disabled={disable}
                                                                        style={{display: settingConnect.connected ? 'none' : 'block'}}
                                                                        primary={!disable}
                                                                        onClick={onSaveSettingConnect}

                                                                >Подключиться</Button>

                                                                <button
                                                                    style={{display: settingConnect.connected ? 'block' : 'none'}}
                                                                    className={inverted ? 'button-link inverted' : 'button-link'}
                                                                    onClick={onDisconnectSettingConnect}
                                                                >Отключиться
                                                                </button>
                                                                <Button
                                                                    style={{display: settingConnect.connected ? 'block' : 'none'}}
                                                                    inverted={inverted} basic
                                                                    color='blue'>Подключено</Button>
                                                            </div>
                                                        </>
                                                    }
                                                ><Segment
                                                    inverted={inverted}>


                                                    <Grid verticalAlign='middle' className='segment-grid'>
                                                        <GridRow>
                                                            <GridColumn width={2}>
                                                                <strong>Название магазина</strong>
                                                            </GridColumn>
                                                            <GridColumn width={3}>
                                                                <Input
                                                                    className='standard-input'
                                                                    value={settingConnect.name_store}
                                                                    onChange={(e) => updateSettingConnect('name_store', e.target.value)}
                                                                    disabled={Boolean(settingConnect.connected)}
                                                                />
                                                            </GridColumn>
                                                        </GridRow>
                                                        <Divider></Divider>
                                                        <GridRow>
                                                            <GridColumn>
                                                                <strong>API-токен</strong>
                                                            </GridColumn>
                                                            <GridColumn>
                                                                <div style={{display: 'block', textAlign: 'center'}}>
                                                                    <Input className='standard-input'
                                                                           value={settingConnect.token_wildberries ?? ''}
                                                                           onChange={(e) => updateSettingConnect('token_wildberries', e.target.value)}
                                                                           disabled={Boolean(settingConnect.connected)}/>
                                                                    {exp()}
                                                                </div>
                                                            </GridColumn>
                                                        </GridRow>
                                                        <Divider></Divider>
                                                        <GridRow>
                                                            <GridColumn>
                                                                <strong>Номер телефона</strong>
                                                            </GridColumn>
                                                            <GridColumn>
                                                                <PhoneNumberInput
                                                                    disabled={Boolean(settingConnect.connected)}
                                                                    className='standard-input'
                                                                    phone={settingConnect.tel}
                                                                    onChange={(value) => updateSettingConnect('tel', value)}/>

                                                            </GridColumn>
                                                        </GridRow>
                                                        <Divider></Divider>
                                                        <GridRow>
                                                            <GridColumn>
                                                                <strong>Часовой пояс</strong>
                                                            </GridColumn>
                                                            <GridColumn>
                                                                <Select
                                                                    disabled={Boolean(settingConnect.connected)}
                                                                    defaultValue={getTimeZoneName(settingConnect.time_zone)}
                                                                        list={timeZones}
                                                                        onSelect={(item: SelectItem | SelectItem[]) => {
                                                                            if (!Array.isArray(item))
                                                                                updateSettingConnect('time_zone', item.id);
                                                                            }
                                                                        }
                                                                        emptyItem={false}

                                                                ></Select>

                                                            </GridColumn>
                                                        </GridRow>
                                                        <Divider
                                                            style={{display: settingConnect.agreement ? 'none' : 'block'}}></Divider>
                                                        <GridRow
                                                            style={{display: settingConnect.agreement ? 'none' : 'block'}}>
                                                            <GridColumn style={{maxWidth: '100%'}}>
                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                    <Checkbox
                                                                        checked={Boolean(settingConnect.agreement)}
                                                                        onChange={() => updateSettingConnect('agreement', !settingConnect.agreement)}/>
                                                                    <p style={{marginLeft: '10px'}}>
                                                                        Я прочел (-ла) и принимаю <a target="_blank"
                                                                                                     href='https://fixcom.kz/legal/privacypolicy'>Политику
                                                                        конфиденциальности</a>
                                                                        и
                                                                        <a target="_blank"
                                                                           href='https://fixcom.kz/legal/agreement'>Пользовательское
                                                                            соглашение</a>
                                                                    </p>
                                                                </div>
                                                            </GridColumn>
                                                        </GridRow>
                                                    </Grid>

                                                </Segment>
                                                </Accordion>}
                                            {activeItemHomeMenu === 'HomeErrorLogs' && <LogErrors></LogErrors>}
                                            {activeItemHomeMenu === 'HomeLogUser' && <LogUser></LogUser>}
                                            {activeItemHomeMenu === 'HomeContacts' && <ContactPage></ContactPage>}
                                        </div>
                                    </Sidebar.Pusher>
                                </Sidebar.Pushable>
                            }

                            {activeItem === 'product' && (
                                <div style={{padding: '10px'}}>
                                    <Products settingProduct={settingProduct}
                                              setSettingProduct={setSettingProduct}
                                              settingConnect={settingConnect}
                                    ></Products>
                                </div>
                            )}


                            {activeItem === 'orders' && (
                                <Sidebar.Pushable
                                    style={{minHeight: '100vh'}}>
                                    <Sidebar
                                        as={Menu}
                                        animation='overlay'
                                        inverted={inverted}
                                        vertical
                                        visible={true}
                                        className='homeSideBar'
                                    >
                                        <Menu.Item as={'div'}>
                                            <Header inverted={inverted} as='h3'>Заказы</Header>
                                        </Menu.Item>

                                        <Menu.Item
                                            as={'a'}

                                            active={activeItemOrderMenu === 'FBS'}
                                            onClick={() => handleItemClickItemOrderMenu('FBS')}
                                        >
                                            FBS
                                        </Menu.Item>

                                        {/*<Menu.Item style={{height: '65px', display: 'flex', alignItems: 'center'}}*/}
                                        {/*           as={'a'}*/}

                                        {/*           active={activeItemOrderMenu === 'FBO'}*/}
                                        {/*           onClick={() => handleItemClickItemOrderMenu('FBO')}*/}
                                        {/*>*/}
                                        {/*    FBO*/}
                                        {/*</Menu.Item>*/}

                                        <Menu.Item style={{height: '65px', display: 'flex', alignItems: 'center'}}
                                                   as={'a'}

                                                   active={activeItemOrderMenu === 'COMM'}
                                                   onClick={() => handleItemClickItemOrderMenu('COMM')}
                                        >
                                            Отчет комиссионера
                                        </Menu.Item>

                                    </Sidebar>
                                    <Sidebar.Pusher>
                                        <div className='sidebar-pusher-div'>
                                            {activeItemOrderMenu === 'FBS' && <OrdersFBS settingOrder={orderSettingFBS}
                                                                                         setSettingOrder={setSettingOrderFBS}></OrdersFBS>}
                                            {activeItemOrderMenu === 'FBO' && <OrdersFBO settingOrder={orderSettingFBO}
                                                                                         setSettingOrder={setSettingOrderFBO}></OrdersFBO>}
                                            {activeItemOrderMenu === 'COMM' &&
                                                <CommissionerReport settingOrder={settingCommissionerReport}
                                                                    setSettingOrder={setSettingCommissionerReport}></CommissionerReport>}
                                        </div>
                                    </Sidebar.Pusher>
                                </Sidebar.Pushable>
                            )}


                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    )
}
