import React, { useState } from 'react';
import { Header, Icon, List, Segment, Grid, Divider, Modal, Image } from 'semantic-ui-react';
import { useGlobalContext } from './GlobalProvider';
import video1 from '../img/video1.svg';
import video2 from '../img/video2.svg';
import video3 from '../img/video3.svg';

const SupportComponent: React.FC = () => {
    const { inverted } = useGlobalContext();
    const [open, setOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState<string | null>(null);

    const handleOpen = (videoUrl: string) => {
        setCurrentVideo(videoUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentVideo(null);
    };

    return (
        <div style={{ marginLeft: '20px' }}>
            <Header inverted={inverted}>Контакты поддержки</Header>
            <Segment inverted={inverted}>
                <Grid inverted={inverted} columns={2}>
                    <Grid.Row>
                        <Grid.Column width={3} style={{ minWidth: 'max-content' }}>
                            <Icon inverted={inverted} name="telegram plane" />
                            <a href="https://t.me/fixcom_support_bot" target="_blank">Написать в Telegram</a>
                        </Grid.Column>
                        <Grid.Column style={{ minWidth: 'max-content' }}>
                            <Icon inverted={inverted} name="phone" />
                            <a href="tel:+77088210292" target="_blank">8 708 821 02 92</a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider inverted={inverted} />
                <p>Время работы: Пн-Пт с 9:00 до 18:00, Сб-Вс выходной по времени Алматы (GMT+5)</p>
            </Segment>

            <Header inverted={inverted}>Полезная информация</Header>
            <Segment inverted={inverted}>
                <List inverted={inverted}>
                    <List.Item style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon name="external alternate" style={{ marginRight: '10px' }} />
                        <a href="https://fixcom.kz/faq/moysklad_wildberries" target="_blank">Руководство по настройке интеграции</a>
                    </List.Item>
                    <Divider inverted={inverted} />
                    <List.Item style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon name="telegram plane" style={{ marginRight: '10px' }} />
                        <a href="https://t.me/Fixcom_MSK" target="_blank">Telegram канал с новостями</a>
                    </List.Item>
                </List>
            </Segment>

            <Header inverted={inverted}>Видео</Header>
            <Segment inverted={inverted}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column style={{ minWidth: '185px', textAlign: 'center' }}>
                            <Image
                                src={video1}
                                style={{ cursor: 'pointer', width: '168px', height: '94px' }}
                                onClick={() => handleOpen("https://www.youtube.com/embed/0sMi4oHTFOc?si=iPmU1Hswn6AV3R85")}
                            />
                            <p className='video-manual-p'>Настройка интеграции</p>
                        </Grid.Column>
                        <Grid.Column style={{ minWidth: '185px', textAlign: 'center' }}>
                            <Image
                                src={video2}
                                style={{ cursor: 'pointer', width: '168px', height: '94px' }}
                                onClick={() => handleOpen("https://www.youtube.com/embed/SbBQE35Xkt0?si=QDwylAyYo3H1VR3E")}
                            />
                            <p className='video-manual-p'>Про синхронизацию цен</p>
                        </Grid.Column>
                        <Grid.Column style={{ minWidth: '185px', textAlign: 'center' }}>
                            <Image
                                src={video3}
                                style={{ cursor: 'pointer', width: '168px', height: '94px' }}
                                onClick={() => handleOpen("https://www.youtube.com/embed/fMTJtmbHWO8?si=8UJ86cd-AXS2aMiy")}
                            />
                            <p className='video-manual-p' style={{fontSize: "0.87em"}}>Про отчет комиссионера</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Modal open={open} onClose={handleClose} size='large' centered className='video-modal'>
                <Modal.Content className='video-manual'>
                    <iframe
                        width="100%"
                        height="500px"
                        src={currentVideo || ''}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default SupportComponent;
