import React, {useEffect, useState} from 'react';
import {Table, Dropdown, DropdownProps, Pagination, Grid, Icon, Menu} from 'semantic-ui-react';
import {ProductLink, TableControls, ActiveMenuItemTableProductLinks} from "../../types";
import {useGlobalContext} from "../GlobalProvider";


interface TableProductLinksProps {
    data: ProductLink[];
    TableControls: TableControls;
    setPage: (page: number) => void;
    setPerPage: (perPage: number) => void;
    onActiveMenuItem?: (activeItem: ActiveMenuItemTableProductLinks) => void;
}

const perPageOptions = [
    {key: '10', value: 10, text: '10'},
    {key: '20', value: 20, text: '20'},
    {key: '50', value: 50, text: '50'},
    {key: '100', value: 100, text: '100'}
];




const TableProductLinks: React.FC<TableProductLinksProps> = ({
                                                                 data,
                                                                 TableControls,
                                                                 setPage,
                                                                 setPerPage,
                                                                 onActiveMenuItem= (activeItem: ActiveMenuItemTableProductLinks) => {}
                                                             }) => {
    const {inverted} = useGlobalContext();
    const [activeItem, setActiveItem] = useState<ActiveMenuItemTableProductLinks>('products');
    const handleItemClick = (name: ActiveMenuItemTableProductLinks) => setActiveItem(name);

    useEffect(() => {
        onActiveMenuItem(activeItem);
    }, [activeItem])

    const getCellsByLink = (link: ProductLink): React.ReactNode[] | React.ReactNode | string => {
        switch (activeItem) {
            case 'products':
                switch (link.status) {
                    case 'OK':
                        return <Icon color='green' name='checkmark'></Icon>
                    case 'conflict':
                        return <span style={{color: 'red'}}>Конфликт товаров</span>
                    case 'not_found_product':
                        return <span style={{color: 'red'}}>Товар не найден</span>
                    default:
                        return <span style={{color: '#a7abac'}}>-</span>;
                }

            case 'balances':
                switch (link.status_balances) {
                    case 'OK':
                        return <Icon color='green' name='checkmark'></Icon>
                    case 'error':
                        return <span style={{color: 'red'}}>Ошибка</span>
                    default:
                        return <span style={{color: '#a7abac'}}>-</span>;
                }
            case 'prices':
                const result: Array<React.ReactNode> = [];
                switch (link.status_prices) {
                    case 'OK':
                        result.push(<Icon color='green' name='checkmark'></Icon>)
                        break;
                    case 'error':
                        result.push(<span style={{color: 'red'}}>Ошибка</span>)
                        break;
                    case 'error_price':
                        result.push(<span style={{color: 'red'}}>Ошибка цены</span>)
                        break;
                    case 'cannot_export':
                        result.push(<span style={{color: '#a7abac'}}>Выгрузка невозможна</span>)
                        break;
                    default:
                        result.push(<span style={{color: '#a7abac'}}>-</span>);
                }

                switch (link.status_discount) {
                    case 'OK':
                        result.push(<Icon color='green' name='checkmark'></Icon>)
                        break;
                    case 'error':
                        result.push(<span style={{color: 'red'}}>Ошибка</span>)
                        break;
                    case 'error_price':
                        result.push(<span style={{color: 'red'}}>Ошибка цены</span>)
                        break;
                    case 'cannot_export':
                        result.push(<span style={{color: '#a7abac'}}>Выгрузка невозможна</span>)
                        break;
                    default:
                        result.push(<span style={{color: '#a7abac'}}>-</span>);
                }

                switch (link.status_prices_discount) {
                    case 'OK':
                        result.push(<Icon color='green' name='checkmark'></Icon>)
                        break;
                    case 'no_answer':
                        result.push(<span style={{color: '#0093ff'}}>Нет ответа от WB</span>)
                        break;
                    case 'error':
                        result.push(<a href='https://seller.wildberries.ru/discount-and-prices/update-history/processed' target='_blank'><span style={{color: 'red'}}>Ошибка</span></a>)
                        break;
                    default:
                        result.push(<span style={{color: '#a7abac'}}>-</span>);
                }


                return result;
            default:
                return <span style={{color: '#a7abac'}}>-</span>;
        }
    }

    const renderTableHeader = (): React.ReactNode[] => {
        switch (activeItem) {
            case 'products':
                return [<Table.HeaderCell key='3a' width={3} textAlign='center'>Статус связи с МойСклад</Table.HeaderCell>]
            case 'balances':
                return [<Table.HeaderCell key='3a' width={3} textAlign='center'>Статус</Table.HeaderCell>]
            case 'prices':
                return [<Table.HeaderCell key='3a' width={2} textAlign='center'>Розничная цена</Table.HeaderCell>,
                    <Table.HeaderCell key='4a' width={2} textAlign='center'>Скидка</Table.HeaderCell>,
                    <Table.HeaderCell key='5a' width={2} textAlign='center'>Статус</Table.HeaderCell>]
            default:
                return [];
        }
    };

    // Функция для рендеринга баркодов, каждый на новой строке
    const renderBarcodes = (barcode: string, uuidHref: string) => {
        const barcodes = barcode.split('||');
        return (
            <div>
                {uuidHref ? (
                    <a target="_blank" href={uuidHref}>
                        {barcodes.map((code, index) => (
                            <React.Fragment key={index}>
                                {code}<br/>
                            </React.Fragment>
                        ))}
                    </a>
                ) : (
                    barcodes.map((code, index) => (
                        <React.Fragment key={index}>
                            {code}<br/>
                        </React.Fragment>
                    ))
                )}
            </div>
        );
    };

    const renderStatusRows = (link: ProductLink): React.ReactNode[] | React.ReactNode | string => {
        const rows = getCellsByLink(link);
        return Array.isArray(rows)
            ? rows.map((cell, index) => (
                <Table.Cell key={index} textAlign="center">{cell}</Table.Cell>
            ))
            : <Table.Cell textAlign="center">{rows}</Table.Cell>
    }

    return (
        <div>
            <Menu pointing secondary inverted={inverted}>
                <Menu.Item
                    name='Товары'
                    active={activeItem === 'products'}
                    onClick={() => handleItemClick('products')}
                />
                <Menu.Item
                    name='Остатки'
                    active={activeItem === 'balances'}
                    onClick={() => handleItemClick('balances')}
                />
                <Menu.Item
                    name='Цены'
                    active={activeItem === 'prices'}
                    onClick={() => handleItemClick('prices')}
                />
            </Menu>
            <Table celled inverted={inverted}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell key='1a' width={2}>Баркод</Table.HeaderCell>
                        <Table.HeaderCell key='2a' width={activeItem === 'prices' ? 8 : 11}>Наименование товара в Wildberries</Table.HeaderCell>
                        {renderTableHeader()}

                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.map((link) => {

                        return (
                            <Table.Row key={link.id}>
                                <Table.Cell>{renderBarcodes(link.barcode, link.uuidHref)}</Table.Cell>
                                <Table.Cell>{link.name_product_wildberries}</Table.Cell>
                                {renderStatusRows(link)}
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>

            <Grid>
                <Grid.Row>
                    <Grid.Column width={8} textAlign="left">
                        <Pagination
                            activePage={TableControls.page}
                            totalPages={TableControls.totalPages}
                            onPageChange={(e, data: DropdownProps) => setPage(Number(data.activePage))}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="right">
                        <Dropdown
                            selection
                            compact
                            value={TableControls.perPage}
                            options={perPageOptions}
                            onChange={(e, data: DropdownProps) => {
                                localStorage.setItem('TableProductLimitsPerPage', String(data.value));
                                setPerPage(Number(data.value));
                                setPage(1);
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default TableProductLinks;
