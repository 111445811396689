import React from 'react';
import { Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {useGlobalContext} from "../GlobalProvider";
import {LogCommissionerReportInterface} from "../../types";


type LogCommissionerReportProps = {
    tableData: LogCommissionerReportInterface[];
}

export const LogCommissionerReport = ({tableData}: LogCommissionerReportProps) => {
    const {inverted} = useGlobalContext();

    return (
        <Table celled inverted={inverted}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>№ отчета</Table.HeaderCell>
                    <Table.HeaderCell>Дата начала</Table.HeaderCell>
                    <Table.HeaderCell>Дата конца</Table.HeaderCell>
                    <Table.HeaderCell>Продажа</Table.HeaderCell>
                    <Table.HeaderCell>Логистика</Table.HeaderCell>
                    <Table.HeaderCell>Штрафы</Table.HeaderCell>
                    <Table.HeaderCell>Валюта</Table.HeaderCell>
                    <Table.HeaderCell>Итог к оплате</Table.HeaderCell>
                    <Table.HeaderCell>Статус</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {tableData.map((data, index) => (
                    <Table.Row key={index}>
                        <Table.Cell><a target="_blank" href={data.uuidHref}>{data.number}</a></Table.Cell>
                        <Table.Cell>{data.start_period}</Table.Cell>
                        <Table.Cell>{data.end_period}</Table.Cell>
                        <Table.Cell>{data.selling}</Table.Cell>
                        <Table.Cell>{data.logistics}</Table.Cell>
                        <Table.Cell>{data.fine}</Table.Cell>
                        <Table.Cell>{data.currency_name}</Table.Cell>
                        <Table.Cell>{data.pay}</Table.Cell>
                        <Table.Cell className={data.status === 'OK' ? 'status-success' : 'status-error'}>
                            {data.status === 'OK' ? 'Загружен успешно' : 'Ошибка'}
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

