import React, {useState} from 'react';
import {Divider, Grid, Input} from 'semantic-ui-react';
import DateTimePicker from 'react-datetime-picker';
import {registerLocale} from "react-datepicker";
import {ru} from 'date-fns/locale';
import {SettingOrder, RequiredAttribute} from "../../types";
import {Select, SelectItem} from "../Select/Select";
import {getCustomerorderAttributesRequiredValues} from "../../requests";
import {formatDate, showError} from "../../tools";
import {useGlobalContext} from "../GlobalProvider";



registerLocale('ru', ru)

function isURL(url: string): boolean {
    let _url;
    try {
        _url = new URL(url);
    } catch (_) {
        return false;
    }

    return _url.protocol === "http:" || _url.protocol === "https:";
}


type OrdersRequiredAttributesFormProps<T extends SettingOrder> = {
    settingOrder: T;
    setSettingOrder: (value: React.SetStateAction<T>) => void;
}



export const OrdersRequiredAttributesForm = <T extends SettingOrder>({settingOrder, setSettingOrder}: OrdersRequiredAttributesFormProps<T>) => {
    const [listSelect, setListSelect] = useState<SelectItem[]>([]);

    const {inverted, account_id} = useGlobalContext();

    const handleAttributeChange = (id: string, newValue: any) => {
        setSettingOrder(prev => ({
            ...prev,
            required_additional_fields: prev.required_additional_fields ? prev.required_additional_fields.map(attr =>
                attr.id === id ? { ...attr, name: newValue } : attr
            ) : null
        }));
    };




    const handleAttributeChangeSelect = (id: string, newValue: any) => {
        setSettingOrder(prev => ({
            ...prev,
            required_additional_fields: prev.required_additional_fields ? prev.required_additional_fields.map(attr =>
                attr.id === id ? { ...attr, customentityValue: newValue } : attr
            ) : null
        }));
    };




    const handleOpenSelect = (id: string) => {
        setListSelect([]);
        getCustomerorderAttributesRequiredValues(account_id, id)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const render = (item: RequiredAttribute) => {
        switch (item.type) {
            case 'string':
            case 'text':
                return <Input fluid value={item.name}

                              placeholder='Введите текст' key={item.id}
                              onChange={(e) => handleAttributeChange(item.id, e.target.value)}
                />
            case 'link':
                return <Input
                    fluid
                    value={item.name}
                    placeholder="Введите ссылку"
                    key={item.id}
                    onChange={(e) => handleAttributeChange(item.id, e.target.value)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        const link = e.target.value;
                        if (!isURL(link)) {
                            handleAttributeChange(item.id, 'https://localhost');
                        }
                    }}
                />

            case 'long':
                return <Input fluid type="text" value={item.name}
                              placeholder='Введите целое число' key={item.id}
                              onChange={(event) => {
                                  const {value} = event.target;
                                  if (!/^[-+]?[0-9]*$/.test(value)) {
                                      event.preventDefault();
                                      return;
                                  }
                                  handleAttributeChange(item.id, event.target.value)

                              }}/>;
            case 'double':
                return (
                    <Input fluid type="number" step="0.01" value={item.name}
                           placeholder="ВВедите число с точкой, например 1.1" key={item.id}
                           onChange={(e) => handleAttributeChange(item.id, e.target.value)}
                    />
                );

            case 'time':

                return <DateTimePicker locale="ru"
                                       className='date-time-picker'
                                       value={item.name}
                                       key={item.id}
                                       onChange={(e) => {

                                           handleAttributeChange(item.id, formatDate(e))
                                       }}
                />;
            default:
                return <Select style={{minWidth: "340px"}}
                               defaultValue={item?.customentityValue}
                               onSelect={(val) => handleAttributeChangeSelect(item.id, val)}
                               list={listSelect}
                               onOpen={() => handleOpenSelect(item.id)}

                ></Select>;


        }
    }



    return (
        <div style={{textAlign: 'left'}}>
            <Grid>
                {Array.isArray(settingOrder.required_additional_fields) && settingOrder.required_additional_fields.map((item, index) => (
                    <React.Fragment key={item.id}>
                        <Grid.Row>
                            <Grid.Column
                                verticalAlign="middle"
                                width={3}
                                style={{minWidth: '400px'}}
                            >
                                <strong>
                                    {item.nameField}
                                </strong>
                            </Grid.Column>
                            <Grid.Column
                                textAlign="left"
                                verticalAlign="middle"
                                style={{width: '370px', paddingTop: '5px'}}
                            >
                                {render(item)} {/* Вызов функции render */}
                            </Grid.Column>
                        </Grid.Row>
                        <Divider/>
                    </React.Fragment>
                ))}
            </Grid>
        </div>

    );
};

