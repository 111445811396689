import Accordion from "../Accordion/Accordion";
import {Button, Checkbox, Confirm, Divider, Grid, GridColumn, GridRow, Icon, Segment} from "semantic-ui-react";
import {useGlobalContext} from "../GlobalProvider";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Sidebar} from "../Sidebar/Sidebar";
import {LogCommissionerReport} from "./LogCommissionerReport";
import {Select, SelectItem} from "../Select/Select";
import DatePicker from 'react-date-picker';
import {OkModal} from '../OkModal';

import {
    Field,
    SyncOrderField,
    SyncOrderAditionalField,
    ApiResponse, SettingCommissionerReport, LogCommissionerReportInterface
} from "../../types";
import {
    getCommissionReportinAttributes,
    getContracts,
    getCounterparty,
    getField, getLogCommissionerReport,
    getOrganizationAccount,
    getStatusProcess,
    putSettingCommissionerReport,
    runProcessLoadCommissionerReport,
} from "../../requests";
import {formatDate, monthDiff, showError, showSuccess} from "../../tools";


type CommissionerReportProps = {
    settingOrder: SettingCommissionerReport;
    setSettingOrder: (value: React.SetStateAction<SettingCommissionerReport>) => void;
}

const syncOrderFieldData: SyncOrderField[] = [

    //  {show: null, name: 'Валюта', divider: true, selectKey: 'currency', url: "currency"},
    {show: 'sales_channel_show', name: 'Канал продаж', divider: true, selectKey: 'sales_channel', url: "saleschannel"},
    {show: 'project_show', name: 'Проект', divider: true, selectKey: 'project', url: "project"},
    {show: 'employee_show', name: 'Сотрудник', divider: true, selectKey: 'employee', url: "employee"},
    {show: 'group_show', name: 'Отдел', divider: true, selectKey: 'group', url: "group"},
]

const syncOrderAditionalField: SyncOrderAditionalField[] = [


    {
        show: 'commission_wildberries_show',
        name: 'Комиссия Wildberries',
        selectKey: 'commission_wildberries',
        sort: ['double'],
        subText: 'Создайте в документе "Отчет комиссионера" дополнительное поле по типу "Число дробное" и выберите его'
    },
    {
        show: 'commission_logistics_show',
        name: 'Логистика',
        selectKey: 'commission_logistics',
        sort: ['double'],
        subText: 'Создайте в документе "Отчет комиссионера" дополнительное поле по типу "Число дробное" и выберите его'
    },
    {
        show: 'commission_fine_show',
        name: 'Штрафы',
        selectKey: 'commission_fine',
        sort: ['double'],
        subText: 'Создайте в документе "Отчет комиссионера" дополнительное поле по типу "Число дробное" и выберите его'
    },
    {
        show: 'additional_payment_show',
        name: 'Доплаты',
        selectKey: 'additional_payment',
        sort: ['double'],
        subText: 'Создайте в документе "Отчет комиссионера" дополнительное поле по типу "Число дробное" и выберите его'
    },
    {
        show: 'warehouse_price_show',
        name: 'Стоимость хранения',
        selectKey: 'warehouse_price',
        sort: ['double'],
        subText: 'Создайте в документе "Отчет комиссионера" дополнительное поле по типу "Число дробное" и выберите его'
    },

    {
        show: 'deduction_show',
        name: 'Удержания',
        selectKey: 'deduction',
        sort: ['double'],
        subText: 'Создайте в документе "Отчет комиссионера" дополнительное поле по типу "Число дробное" и выберите его'
    },

    {
        show: 'acceptance_show',
        name: 'Платная приемка',
        selectKey: 'acceptance',
        sort: ['double'],
        subText: 'Создайте в документе "Отчет комиссионера" дополнительное поле по типу "Число дробное" и выберите его'
    },

]


const showPanelData = [
    // {name: 'Валюта', field: 'currency_show', header: false},
    // {name: 'Договор', field: 'contract_show', header: false},
    {name: 'Канал продаж', field: 'sales_channel_show', header: false},
    {name: 'Проект', field: 'project_show', header: false},
    {name: 'Сотрудник', field: 'employee_show', header: false},
    {name: 'Отдел', field: 'group_show', header: false},
    {name: 'Счет организации', field: 'organization_account_show', header: false},
    {name: 'Дополнительная информация', field: 'dopinfo', header: true},
    {name: 'Комиссия', field: 'commission_wildberries_show', header: false},
    {name: 'Логистика', field: 'commission_logistics_show', header: false},
    {name: 'Штраф', field: 'commission_fine_show', header: false},
    {name: 'Доплаты', field: 'additional_payment_show', header: false},
    {name: 'Стоимость хранения', field: 'warehouse_price_show', header: false},
    {name: 'Удержания', field: 'deduction_show', header: false},
    {name: 'Платная приемка', field: 'acceptance_show', header: false},


];


export const CommissionerReport = ({settingOrder, setSettingOrder}: CommissionerReportProps) => {
    const {inverted, account_id, activeSettingsId, connected} = useGlobalContext();
    const [advancedSettingOpen, setAdvancedSettingOpen] = useState(false);
    const [listSelect, setListSelect] = useState<SelectItem[]>([]);
    const [tableData, setTableData] = useState<LogCommissionerReportInterface[]>([]);
    const [openConfirmLoadCommissionerReport, setOpenConfirmLoadCommissionerReport] = useState(false);
    const [timers, setTimers] = useState<Map<string, NodeJS.Timeout>>(new Map());
    const timersRef = useRef<Map<string, NodeJS.Timeout>>(timers);
    const [openModal, setOpenModal] = useState(false);

    const updateSettingOrder = (key: string, value: any) => {
        setSettingOrder(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const updateSettingOrderSelect = (key: string, value: any) => {
        setSettingOrder(prev => ({
            ...prev,
            [key + "_id"]: value.id,
            [key + "_name"]: value.name,
        }))
    }







    useEffect(() => {
        if (!account_id || !activeSettingsId) return;
        getLogCommissionerReportData();
        // eslint-disable-next-line
    }, [account_id, activeSettingsId])


    const getSelectDefaultValue = (key: string): Field => {
        if (!settingOrder) return {id: '', name: ''};
        return {
            id: settingOrder[key + '_id'],
            name: settingOrder[key + '_name']
        };
    };


    const onSaveSettingOrder = () => {

        putSettingCommissionerReport(account_id, activeSettingsId, settingOrder)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    showSuccess("Сохранено", inverted);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleOpenSelect = (url: string) => {
        setListSelect([]);
        getField(account_id, url)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const getLogCommissionerReportData = () => {
        getLogCommissionerReport(account_id, activeSettingsId)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setTableData(data.data);

                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    const getContract = () => {
        setListSelect([]);
        if (!settingOrder.counterparty_id || !settingOrder.organization_id) return;
        getContracts(account_id, settingOrder.counterparty_id, settingOrder.organization_id)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleTextChange = (text: string) => {

        if (!text) {
            setListSelect([]);
            return;
        }
        setListSelect([]);
        getCounterparty(account_id, text)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    const handleOrganizationAccount = () => {
        if (!settingOrder.organization_id) return;
        setListSelect([]);
        getOrganizationAccount(account_id, settingOrder.organization_id)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    console.error("handleOrganizationAccount", data.error)
                }
            })
            .catch(error => {
                console.error("handleOrganizationAccount", error)
            });
    }

    const handleOpenSelectCustomerOrderAttributes = (sort: string[]) => {
        setListSelect([]);

        getCommissionReportinAttributes(account_id, sort)
            .then(value => {

                const data: ApiResponse<SelectItem[]> = value.data;
                if (data.success && data.data) {
                    setListSelect(data.data);
                } else {
                    if (data.error)
                        showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


//***************************************  Таймеры начало ***********************************************
    const startStatusCheckTimer = useCallback((key: string) => {
        const id = setInterval(() => checkStatusProcess(key), 5000);
        setTimers(prevTimers => new Map(prevTimers).set(key, id));
    }, []);

    const stopStatusCheckTimer = useCallback((key: string) => {
        const timer = timersRef.current.get(key);
        if (timer) {
            clearInterval(timer);
            setTimers(prevTimers => {
                const newTimers = new Map(prevTimers);
                newTimers.delete(key);
                return newTimers;
            });
        }
    }, []);

    useEffect(() => {
        timersRef.current = timers;
    }, [timers]);

    useEffect(() => {
        return () => {
            timersRef.current.forEach(timer => clearInterval(timer));
        };
    }, []);

    useEffect(() => {
        const timerKeys = [
            'load_commissioner_report'
        ];

        timerKeys.forEach(key => {

            if (Boolean(settingOrder[key]) && !timersRef.current.has(key)) {
                startStatusCheckTimer(key);
            }
        });
    }, [settingOrder, startStatusCheckTimer]);

    // Запускаем процесс с url
    const handleRunProcess = (url: string) => {
        const startDate = new Date(settingOrder.start_period);
        const endDate = new Date(settingOrder.end_period);
        if (startDate > endDate) {
            showError('Начало периода не должно быть больше конца периода', inverted)
            return;
        }

        const monthsDifference = monthDiff(startDate, endDate);

        if (monthsDifference > 3) {
            showError('Период не должен быть больше трех месяцев', inverted)
            return;
        }

        const params = {
            start_period: settingOrder.start_period,
            end_period: settingOrder.end_period,
        }

        runProcessLoadCommissionerReport(account_id, activeSettingsId, params)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    showSuccess('Задача успешно запущена', inverted);
                    updateSettingOrder(url, true);
                    startStatusCheckTimer(url);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    // Проверяем статус процесса
    const checkStatusProcess = (url: string) => {
        getStatusProcess(account_id, activeSettingsId, url)
            .then(value => {
                const data = value.data;
                if (data.success) {

                    updateSettingOrder(url, false);
                    stopStatusCheckTimer(url);
                    if (url === 'load_commissioner_report') {
                        setOpenModal(true);
                        getLogCommissionerReportData();
                    }
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    };

    //***************************************  Таймеры конец ***********************************************

    return (
        <>
            {openModal &&
                <OkModal
                    className='modal-dialog'
                    content="Загрузка отчета завершена"
                    okButtonClick={() => {
                        setOpenModal(false);
                    }}
                />
            }
            <Sidebar autoClose={false} position='right' isOpen={advancedSettingOpen}
                     onClose={() => setAdvancedSettingOpen(false)}
            ><Segment inverted={inverted} style={{borderRadius: 0, minHeight: '100%'}}>
                <Grid verticalAlign='middle'>
                    <GridRow>
                        <GridColumn floated='left' width={13}><h3>Системные данные</h3></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <div className='advanced-menu-icon-back'
                                 onClick={() => setAdvancedSettingOpen(false)}>
                                <Icon name='arrow right' size='big'/>
                            </div>
                        </GridColumn>
                    </GridRow>

                    {showPanelData &&
                        showPanelData.map((value) => (
                            <React.Fragment key={value.field}>
                                <Divider/>
                                <GridRow>
                                    {!value.header ? <>
                                            <GridColumn floated='left' width={13}>
                                                <strong>{value.name}</strong>
                                            </GridColumn>
                                            <GridColumn floated='right' width={3}>
                                                <Checkbox
                                                    slider
                                                    className={inverted ? 'dark' : ''}
                                                    checked={Boolean(settingOrder[value.field])}
                                                    onClick={() => updateSettingOrder(value.field, !settingOrder[value.field])}
                                                />
                                            </GridColumn>
                                        </>
                                        : <GridColumn floated='left' width={13}><h3>{value.name}</h3></GridColumn>}

                                </GridRow>

                            </React.Fragment>
                        ))
                    }

                </Grid>

            </Segment></Sidebar>
            <Accordion title={'Отчет комиссионера'} saveName={'AccordionCommissionerReport'}
                       inverted={inverted}
                       headerContent={
                           <>
                               <Checkbox className={inverted ? 'dark' : ''} toggle
                                         checked={Boolean(settingOrder.sync)}
                                         onClick={() => updateSettingOrder('sync', !settingOrder.sync)}
                               ></Checkbox>
                               <label style={{
                                   color: inverted ? 'white' : '#706f6f',
                                   width: "100%",
                                   marginLeft: "30px"
                               }}>Автоматически загружать новый отчет</label>
                               <div className='buttons-connect'>
                                   <Button inverted={inverted} basic
                                           onClick={() => setAdvancedSettingOpen(true)

                                           }
                                   >Расширенные настройки</Button>
                                   <Button primary inverted={inverted}
                                           onClick={onSaveSettingOrder}
                                   >Сохранить</Button>

                               </div>
                           </>
                       }>
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>

                        <GridRow>
                            <GridColumn>
                                <strong>Организация</strong>
                            </GridColumn>
                            <GridColumn>
                                <Select defaultValue={getSelectDefaultValue('organization')}
                                        list={listSelect}
                                        onSelect={(item => {
                                            updateSettingOrderSelect('organization', item);
                                            updateSettingOrderSelect('organization_account', {id: "", name: ""})
                                            updateSettingOrderSelect('contract', {id: "", name: ""})
                                        })}
                                        onOpen={() => handleOpenSelect('organization')}
                                ></Select>
                            </GridColumn>
                        </GridRow>

                        {Boolean(settingOrder.organization_account_show) && <GridRow>
                            <GridColumn width={2}>
                                <strong>Счет организации</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('organization_account')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('organization_account', item))}
                                        onOpen={() => handleOrganizationAccount()}
                                ></Select>
                            </GridColumn>
                        </GridRow>}


                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Контрагент</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('counterparty')}
                                        list={listSelect}
                                        placeholder='Начните печатать'
                                        onSelect={(item => {
                                            updateSettingOrderSelect('counterparty', item);
                                            updateSettingOrderSelect('contract', {id: "", name: ""})
                                        })}
                                        onOpen={(text) => handleTextChange(text)}
                                        onTextEnter={(text) => handleTextChange(text)}
                                ></Select>
                            </GridColumn>
                        </GridRow>

                        {/*<Divider></Divider>*/}

                        {/*<GridRow>*/}
                        {/*    <GridColumn>*/}
                        {/*        <strong>Тип заказов</strong>*/}
                        {/*    </GridColumn>*/}
                        {/*    <GridColumn>*/}
                        {/*        <Select defaultValue={getSelectDefaultValue('type_delivery')}*/}
                        {/*                onSelect={(item => {*/}
                        {/*                    updateSettingOrderSelect('type_delivery', item)*/}
                        {/*                })}*/}
                        {/*                list={[{id: 'ALL', name: 'Все'},*/}
                        {/*                    {id: 'FBS', name: 'FBS'},*/}
                        {/*                    {id: 'FBO', name: 'FBO'}*/}
                        {/*                ]}*/}
                        {/*                emptyItem={false}*/}
                        {/*        ></Select>*/}
                        {/*    </GridColumn>*/}
                        {/*</GridRow>*/}

                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Договор комиссии</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('contract')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('contract', item))}
                                        onOpen={getContract}

                                ></Select>
                            </GridColumn>
                        </GridRow>


                        {syncOrderFieldData && syncOrderFieldData.map((value) => (
                            (value.show === null || Boolean(settingOrder[value.show])) &&
                            (<React.Fragment key={value.selectKey || value.checked}>
                                {value.divider && <Divider></Divider>}
                                <GridRow>
                                    <GridColumn width={2}>
                                        <strong>{value.name}</strong>
                                    </GridColumn>
                                    {value.selectKey &&
                                        <GridColumn width={2}>
                                            <Select defaultValue={getSelectDefaultValue(value.selectKey)}
                                                    list={listSelect}
                                                    onSelect={(item => updateSettingOrderSelect(value.selectKey ?? "", item))}
                                                    onOpen={() => handleOpenSelect(value.url ?? "")}
                                            ></Select>
                                        </GridColumn>}
                                    {value.checked &&
                                        <GridColumn width={2}>
                                            <Checkbox slider className={inverted ? 'dark' : ''}
                                                      checked={Boolean(settingOrder[value.checked])}
                                                      onClick={() => value.checked && updateSettingOrder(value.checked, !settingOrder[value.checked])}
                                            ></Checkbox>
                                        </GridColumn>
                                    }
                                </GridRow>
                            </React.Fragment>)
                        ))}


                        {syncOrderAditionalField && syncOrderAditionalField.map((item) => (
                            (item.show === null || Boolean(settingOrder[item.show])) &&
                            (<React.Fragment key={item.selectKey}>
                                <Divider></Divider>
                                <GridRow>
                                    <GridColumn width={2}>
                                        <strong>{item.name}</strong>
                                        <p className='subtext'>{item.subText}</p>
                                    </GridColumn>
                                    {item.selectKey &&
                                        <GridColumn width={2}>
                                            <Select defaultValue={getSelectDefaultValue(item.selectKey)}
                                                    list={listSelect}

                                                    onSelect={(value => updateSettingOrderSelect(item.selectKey, value))}
                                                    onOpen={() => handleOpenSelectCustomerOrderAttributes(item.sort)}
                                            ></Select>

                                        </GridColumn>}
                                </GridRow>
                            </React.Fragment>)
                        ))}

                        <Divider></Divider>
                        <GridRow>
                            <GridColumn>
                                <strong>Загрузка отчетов</strong>
                                <p className='subtext'>Возможно загрузить отчеты за последние 3 месяца.</p>
                            </GridColumn>

                            <GridColumn width={2}>
                                <GridRow>

                                    <p className='subtext'>Начало периода</p>
                                    <DatePicker locale="ru"
                                                calendarIcon={null}
                                                value={settingOrder.start_period}
                                                className='date-time-picker-300'
                                                onChange={(e) => {
                                                    const selectedDate = e instanceof Date ? e : new Date();
                                                    setSettingOrder(prev => ({
                                                        ...prev,
                                                        start_period: formatDate(selectedDate),
                                                    }))
                                                }}
                                    />
                                </GridRow>
                                <GridRow style={{marginTop: '10px'}}>
                                    <div style={{display: 'flex', alignItems: "center"}}>
                                        <div>
                                            <p className='subtext'>Конец периода</p>
                                            <DatePicker
                                                locale="ru"
                                                calendarIcon={null}
                                                className='date-time-picker-300'
                                                value={new Date(settingOrder.end_period)}
                                                onChange={(e) => {
                                                    const selectedDate = e instanceof Date ? e : new Date();
                                                    setSettingOrder(prev => ({
                                                        ...prev,
                                                        end_period: formatDate(selectedDate),
                                                    }))
                                                }}
                                            />

                                        </div>

                                    </div>
                                </GridRow>
                                <GridRow style={{marginTop: '10px'}}>
                                    <button
                                        disabled={Boolean(settingOrder.load_commissioner_report) || !connected}
                                        onClick={() => setOpenConfirmLoadCommissionerReport(true)}
                                        style={{paddingLeft: 0}}
                                        className={inverted ? 'button-link inverted' : 'button-link'}>Загрузить
                                    </button>
                                    {Boolean(settingOrder.load_commissioner_report) &&
                                        <Icon loading name='spinner' inverted={inverted}/>
                                    }
                                    <Confirm
                                        className='modal-dialog's
                                        open={openConfirmLoadCommissionerReport}
                                        content='Запустить загрузку финансовых отчетов из Wildberries в МойСклад?'
                                        cancelButton='Нет'
                                        confirmButton='Да'
                                        onCancel={() => setOpenConfirmLoadCommissionerReport(false)}
                                        onConfirm={() => {
                                            setOpenConfirmLoadCommissionerReport(false);
                                            handleRunProcess('load_commissioner_report')
                                        }}
                                    />
                                </GridRow>
                            </GridColumn>


                        </GridRow>

                    </Grid>
                </Segment>
            </Accordion>


            <Accordion title="Документы" saveName={'AccordionCommissionerReportDocs'}
                       inverted={inverted}>
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Не создавать входящий платеж</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Checkbox slider className={inverted ? 'dark' : ''}
                                          checked={Boolean(settingOrder.not_create_incoming_payment)}
                                          onClick={() => updateSettingOrder('not_create_incoming_payment', !settingOrder.not_create_incoming_payment)}/>
                            </GridColumn>
                        </GridRow>

                    </Grid>
                </Segment>
            </Accordion>

            <Accordion title="Еженедельные отчеты" saveName={'AccordionCommissionerReportWeeklyReports'}
                       inverted={inverted}
                       headerContent={
                           <div className='buttons-connect'>
                               <Button basic inverted={inverted} icon='refresh' onClick={() => {
                                   getLogCommissionerReportData();
                               }}></Button>
                           </div>
                       }>

                <LogCommissionerReport tableData={tableData}></LogCommissionerReport>


            </Accordion>

            <div style={{height: "200px"}}>
            </div>
        </>
    )
}