import React, { useEffect, useState} from 'react'
import {Input} from 'semantic-ui-react'


type PhoneNumberInputProps = {
    className?: string;
    phone?: string;
    onChange?: (value:string) => void;
    disabled?: boolean;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({className = '', phone = '', onChange = () => {}, disabled = true}:PhoneNumberInputProps) => {
    const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        setPhoneNumber(phone);
    }, [phone]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        let formattedValue = value.replace(/[^+\d]/g, ''); // Удаляем все символы, кроме + и цифр

        const limit = 15;

        if (formattedValue.length <= limit) {
            setPhoneNumber(formattedValue);
            onChange(formattedValue);
        }
    };

    return (
        <Input
            disabled={disabled}
            placeholder='+7XXXXXXXXXX'
            value={phoneNumber}
            onChange={handleChange}
            className={className}
        />
    )
};

export default PhoneNumberInput;


