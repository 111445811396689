import Accordion from "../Accordion/Accordion";
import {Button, Checkbox, Divider, Grid, GridColumn, GridRow, Icon, Input, Segment} from "semantic-ui-react";
import {useGlobalContext} from "../GlobalProvider";
import React, {useEffect, useState} from "react";
import {Sidebar} from "../Sidebar/Sidebar";
import {Select, SelectItem} from "../Select/Select";

import {
    Field,
    SettingOrderFBO,
    SyncOrderField,
    SyncOrderAditionalField,
    ApiResponse
} from "../../types";
import {OrdersRequiredAttributesForm} from "./OrdersRequiredAttributesForm";
import {
    getContracts,
    getCounterparty, getCustomerOrderAttributes,
    getField,
    getOrganizationAccount, putSettingOrder,
} from "../../requests";
import {showError, showSuccess} from "../../tools";


type OrdersFBOProps = {
    settingOrder: SettingOrderFBO;
    setSettingOrder: (value: React.SetStateAction<SettingOrderFBO>) => void;
}

const syncOrderFieldData: SyncOrderField[] = [

    {show: 'reservation_show', name: 'Резервирование товаров', divider: true, checked: 'reservation'},
    {show: 'currency_show', name: 'Валюта', divider: true, selectKey: 'currency', url: "currency"},
    {show: 'sales_channel_show', name: 'Канал продаж', divider: true, selectKey: 'sales_channel', url: "saleschannel"},
    {show: 'project_show', name: 'Проект', divider: true, selectKey: 'project', url: "project"},
    {show: 'employee_show', name: 'Сотрудник', divider: true, selectKey: 'employee', url: "employee"},
    {show: 'group_show', name: 'Отдел', divider: true, selectKey: 'group', url: "group"},
]

const syncOrderAditionalField: SyncOrderAditionalField[] = [

    {
        show: 'data_delivery_show',
        name: 'Дата доставки',
        selectKey: 'data_delivery',
        sort: ['time'],
        subText: "Тип поля дата"
    },
    {
        show: 'data_return_show',
        name: 'Дата возврата',
        selectKey: 'data_return',
        sort: ['time'],
        subText: "Тип поля дата"
    },
    {
        show: 'commission_show',
        name: 'Комиссия - Wildberries',
        selectKey: 'commission_wildberries',
        sort: ['string', 'text'],
        subText: "Тип поля строка или текст"
    },
    {
        show: 'commission_show',
        name: 'Комиссия - Логистики',
        selectKey: 'commission_logistics',
        sort: ['string', 'text'],
        subText: "Тип поля строка или текст"
    },
    {
        show: 'commission_show',
        name: 'Комиссия - Штрафы',
        selectKey: 'commission_fine',
        sort: ['string', 'text'],
        subText: "Тип поля строка или текст"
    },

]


const showPanelData = [
    {name: 'Резервирование товаров', field: 'reservation_show', header: false},
    {name: 'Валюта', field: 'currency_show', header: false},
    {name: 'Канал продаж', field: 'sales_channel_show', header: false},
    {name: 'Проект', field: 'project_show', header: false},
    {name: 'Сотрудник', field: 'employee_show', header: false},
    {name: 'Отдел', field: 'group_show', header: false},
    {name: 'Счет организации', field: 'organization_account_show', header: false},
    {name: 'Тип нумерации заказов', field: 'order_numbering_type_show', header: false},
    {name: 'Дополнительная информация', field: 'dopinfo', header: true},
    {name: 'Дата доставки', field: 'data_delivery_show', header: false},
    {name: 'Дата возврата', field: 'data_return_show', header: false},
    {name: 'Комиссия', field: 'commission_show', header: false},


];


export const OrdersFBO = ({settingOrder, setSettingOrder}: OrdersFBOProps) => {
    const {inverted, account_id, activeSettingsId} = useGlobalContext();
    const [advancedSettingOpen, setAdvancedSettingOpen] = useState(false);
    const [listSelect, setListSelect] = useState<SelectItem[]>([]);

    const updateSettingOrder = (key: string, value: any) => {
        setSettingOrder(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const updateSettingOrderSelect = (key: string, value: any) => {
        setSettingOrder(prev => ({
            ...prev,
            [key + "_id"]: value.id,
            [key + "_name"]: value.name,
        }))
    }


    useEffect(() => {

    }, [settingOrder])


    const getSelectDefaultValue = (key: string): Field => {
        if (!settingOrder) return {id: '', name: ''};
        return {
            id: settingOrder[key + '_id'],
            name: settingOrder[key + '_name']
        };
    };


    const onSaveSettingOrder = () => {

        putSettingOrder(account_id, activeSettingsId, "FBO", settingOrder)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    showSuccess("Сохранено", inverted);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleOpenSelect = (url: string) => {
        setListSelect([]);
        getField(account_id, url)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const getContract = () => {
        setListSelect([]);
        if (!settingOrder.counterparty_id || !settingOrder.organization_id) return;
        getContracts(account_id, settingOrder.counterparty_id, settingOrder.organization_id)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    const handleTextChange = (text: string) => {

        if (!text) {
            setListSelect([]);
            return;
        }
        setListSelect([]);
        getCounterparty(account_id, text)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }

    const handleOrganizationAccount = () => {
        if (!settingOrder.organization_id) return;
        setListSelect([]);
        getOrganizationAccount(account_id, settingOrder.organization_id)
            .then(value => {
                const data = value.data;
                if (data.success) {
                    setListSelect(data.data);
                } else {
                    console.error("handleOrganizationAccount", data.error)
                }
            })
            .catch(error => {
                console.error("handleOrganizationAccount", error)
            });
    }

    const handleOpenSelectCustomerOrderAttributes = (sort: string[]) => {
        setListSelect([]);

        getCustomerOrderAttributes(account_id, sort)
            .then(value => {

                const data: ApiResponse<SelectItem[]> = value.data;
                if (data.success && data.data) {
                    setListSelect(data.data);
                } else {
                    if (data.error)
                        showError(data.error, inverted, false);
                }
            })
            .catch(error => {
                if (error.response)
                    showError(error.response.data.error, inverted, false);
            });
    }


    return (
        <>
            <Sidebar autoClose={false} position='right' isOpen={advancedSettingOpen}
                     onClose={() => setAdvancedSettingOpen(false)}
            ><Segment inverted={inverted} style={{borderRadius: 0, minHeight: '100%'}}>
                <Grid verticalAlign='middle'>
                    <GridRow>
                        <GridColumn floated='left' width={13}><h2>Системные данные</h2></GridColumn>
                        <GridColumn floated='right' width={3}>
                            <div style={{height: "max-content", width: "max-content"}}
                                 onClick={() => setAdvancedSettingOpen(false)}>
                                <Icon fitted name='arrow right' size='big' style={{cursor: 'pointer'}}/>
                            </div>
                        </GridColumn>
                    </GridRow>

                    {showPanelData &&
                        showPanelData.map((value) => (
                            <React.Fragment key={value.field}>
                                <Divider/>
                                <GridRow>
                                    {!value.header ? <>
                                            <GridColumn floated='left' width={13}>
                                                <strong>{value.name}</strong>
                                            </GridColumn>
                                            <GridColumn floated='right' width={3}>
                                                <Checkbox
                                                    slider
                                                    className={inverted ? 'dark' : ''}
                                                    checked={Boolean(settingOrder[value.field])}
                                                    onClick={() => updateSettingOrder(value.field, !settingOrder[value.field])}
                                                />
                                            </GridColumn>
                                        </>
                                        : <GridColumn floated='left' width={13}><h3>{value.name}</h3></GridColumn>}

                                </GridRow>

                            </React.Fragment>
                        ))
                    }

                </Grid>

            </Segment></Sidebar>
            <Accordion title={'Синхронизация заказов FBO'} saveName={'AccordionOrdersSyncFBO'}
                       inverted={inverted}
                       headerContent={
                           <>
                               <Checkbox className={inverted ? 'dark' : ''} toggle
                                         checked={Boolean(settingOrder.sync)}
                                         onClick={() => updateSettingOrder('sync', !settingOrder.sync)}
                               ></Checkbox>
                               <label style={{
                                   color: inverted ? 'white' : '#706f6f',
                                   width: "100%",
                                   marginLeft: "30px"
                               }}>Загрузка каждые 5 минут</label>
                               <div className='buttons-connect'>
                                   <Button inverted={inverted} basic
                                           onClick={() => setAdvancedSettingOpen(true)

                                           }
                                   >Расширенные настройки</Button>
                                   <Button primary inverted={inverted}
                                           onClick={onSaveSettingOrder}
                                   >Сохранить</Button>

                               </div>
                           </>
                       }>
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>

                        <GridRow>
                            <GridColumn>
                                <strong>Организация</strong>
                            </GridColumn>
                            <GridColumn>
                                <Select defaultValue={getSelectDefaultValue('organization')}
                                        list={listSelect}
                                        onSelect={(item => {
                                            updateSettingOrderSelect('organization', item);
                                            updateSettingOrderSelect('organization_account', {id: "", name: ""})
                                            updateSettingOrderSelect('contract', {id: "", name: ""})
                                        })}
                                        onOpen={() => handleOpenSelect('organization')}
                                ></Select>
                            </GridColumn>
                        </GridRow>

                        {Boolean(settingOrder.organization_account_show) && <GridRow>
                            <GridColumn width={2}>
                                <strong>Счет организации</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('organization_account')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('organization_account', item))}
                                        onOpen={() => handleOrganizationAccount()}
                                ></Select>
                            </GridColumn>
                        </GridRow>}


                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Контрагент</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('counterparty')}
                                        list={listSelect}
                                        onSelect={(item => {
                                            updateSettingOrderSelect('counterparty', item);
                                            updateSettingOrderSelect('contract', {id: "", name: ""})

                                        })}
                                        onOpen={(text) => handleTextChange(text)}
                                        onTextEnter={(text) => handleTextChange(text)}
                                ></Select>
                            </GridColumn>
                        </GridRow>

                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Договор</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('contract')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('contract', item))}
                                        onOpen={getContract}

                                ></Select>
                            </GridColumn>
                        </GridRow>

                        {Boolean(settingOrder.order_numbering_type_show) && <><Divider></Divider>
                            <GridRow>
                                <GridColumn width={2}>
                                    <strong>Тип нумерации заказов</strong>
                                </GridColumn>
                                <GridColumn width={2}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Select style={{minWidth: '300px'}}
                                                defaultValue={getSelectDefaultValue('order_numbering_type')}
                                                list={[{id: 'moysklad', name: 'Нумерация МойСклад'},
                                                    {id: 'wildberries', name: 'Нумерация Wildberries'}]}
                                                onSelect={(item => updateSettingOrderSelect('order_numbering_type', item))}
                                                emptyItem={false}
                                        ></Select>
                                        {settingOrder.order_numbering_type_id === 'moysklad' &&
                                            <Input value={settingOrder.order_numbering_type_prefix}
                                                   onChange={event => setSettingOrder(value => ({
                                                       ...value,
                                                       order_numbering_type_prefix: event.target.value
                                                   }))}
                                                   style={{width: '100px', marginLeft: '10px'}}></Input>}
                                    </div>
                                </GridColumn>
                            </GridRow></>}


                        {syncOrderFieldData && syncOrderFieldData.map((value) => (
                            (value.show === null || Boolean(settingOrder[value.show])) &&
                            (<React.Fragment key={value.selectKey || value.checked}>
                                {value.divider && <Divider></Divider>}
                                <GridRow>
                                    <GridColumn width={2}>
                                        <strong>{value.name}</strong>
                                    </GridColumn>
                                    {value.selectKey &&
                                        <GridColumn width={2}>
                                            <Select defaultValue={getSelectDefaultValue(value.selectKey)}
                                                    list={listSelect}
                                                    onSelect={(item => updateSettingOrderSelect(value.selectKey ?? "", item))}
                                                    onOpen={() => handleOpenSelect(value.url ?? "")}
                                            ></Select>
                                        </GridColumn>}
                                    {value.checked &&
                                        <GridColumn width={2}>
                                            <Checkbox slider className={inverted ? 'dark' : ''}
                                                      checked={Boolean(settingOrder[value.checked])}
                                                      onClick={() => value.checked && updateSettingOrder(value.checked, !settingOrder[value.checked])}
                                            ></Checkbox>
                                        </GridColumn>
                                    }
                                </GridRow>
                            </React.Fragment>)
                        ))}


                        {syncOrderAditionalField && syncOrderAditionalField.map((item) => (
                            (item.show === null || Boolean(settingOrder[item.show])) &&
                            (<React.Fragment key={item.selectKey}>
                                <Divider></Divider>
                                <GridRow>
                                    <GridColumn width={2}>
                                        <strong>{item.name}</strong>
                                    </GridColumn>
                                    {item.selectKey &&
                                        <GridColumn width={2}>
                                            <Select defaultValue={getSelectDefaultValue(item.selectKey)}
                                                    list={listSelect}

                                                    onSelect={(value => updateSettingOrderSelect(item.selectKey, value))}
                                                    onOpen={() => handleOpenSelectCustomerOrderAttributes(item.sort)}
                                            ></Select>
                                            <p className='subtext'>{item.subText}</p>
                                        </GridColumn>}
                                </GridRow>
                            </React.Fragment>)
                        ))}

                    </Grid>
                </Segment>
            </Accordion>

            <Accordion title='Статусы' saveName={'AccordionOrdersSyncStatusFBO'}
                       inverted={inverted}
            >
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>
                        <GridRow>
                            <GridColumn width={2}>
                                <p className='subtext'>Статусы Wildberries</p>
                                <strong>Новые</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <p className='subtext'>Статусы МойСклад</p>
                                <Select
                                    defaultValue={getSelectDefaultValue('new_state')}
                                    list={listSelect}
                                    onSelect={(item => updateSettingOrderSelect('new_state', item))}
                                    onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Доставлено</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select
                                    defaultValue={getSelectDefaultValue('delivered')}
                                    list={listSelect}
                                    onSelect={(item => updateSettingOrderSelect('delivered', item))}
                                    onOpen={() => handleOpenSelect('states')}
                                ></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Отменен</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('canceled')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('canceled', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Возврат (На склад WB)</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Select defaultValue={getSelectDefaultValue('arrived')}
                                        list={listSelect}
                                        onSelect={(item => updateSettingOrderSelect('arrived', item))}
                                        onOpen={() => handleOpenSelect('states')}></Select>
                            </GridColumn>
                        </GridRow>

                    </Grid>

                </Segment>
            </Accordion>

            <Accordion title="Документы" saveName={'AccordionOrdersDocsFBO'}
                       inverted={inverted}>
                <Segment inverted={inverted}>
                    <Grid verticalAlign='middle' className='segment-grid'>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Создавать отгрузку и входящий платеж при доставке</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Checkbox slider className={inverted ? 'dark' : ''}
                                          checked={Boolean(settingOrder.create_shipment_incoming_payment)}
                                          onClick={() => updateSettingOrder('create_shipment_incoming_payment', !settingOrder.create_shipment_incoming_payment)}/>
                            </GridColumn>
                        </GridRow>
                        {Boolean(settingOrder.create_shipment_incoming_payment) && <GridRow>
                            <GridColumn width={2}>
                                <strong>Не удалять документы при отмене заказа</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Checkbox slider className={inverted ? 'dark' : ''}
                                          checked={Boolean(settingOrder.not_delete_shipment_incoming_payment)}
                                          onClick={() => updateSettingOrder('not_delete_shipment_incoming_payment', !settingOrder.not_delete_shipment_incoming_payment)}/>
                            </GridColumn>
                        </GridRow>}
                        <Divider></Divider>
                        <GridRow>
                            <GridColumn width={2}>
                                <strong>Создавать возврат и исходящий платеж при возврате</strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Checkbox slider className={inverted ? 'dark' : ''}
                                          checked={Boolean(settingOrder.create_return_out_payment)}
                                          onClick={() => updateSettingOrder('create_return_out_payment', !settingOrder.create_return_out_payment)}/>

                            </GridColumn>
                        </GridRow>
                        {Boolean(settingOrder.create_return_out_payment) && <GridRow>
                            <GridColumn width={2}>
                                <strong>Не создавать документы возврат и исх. платеж </strong>
                            </GridColumn>
                            <GridColumn width={2}>
                                <Checkbox slider className={inverted ? 'dark' : ''}
                                          checked={Boolean(settingOrder.not_delete_return_out_payment)}
                                          onClick={() => updateSettingOrder('not_delete_return_out_payment', !settingOrder.not_delete_return_out_payment)}/>
                            </GridColumn>
                        </GridRow>}
                    </Grid>
                </Segment>
            </Accordion>


            <Accordion title='Обязательные дополнительные поля' saveName={'AccordionOrdersRequiredFBO'}
                       inverted={inverted}>
                <Segment inverted={inverted}>
                    <OrdersRequiredAttributesForm settingOrder={settingOrder}
                                                  setSettingOrder={setSettingOrder}
                    ></OrdersRequiredAttributesForm>
                </Segment>
            </Accordion>
            <div style={{height: "200px"}}>
            </div>
        </>
    )
}